import React, { useContext, useEffect, useState, useRef, useMemo } from "react";
import {
	useParams,
	useNavigate,
	useSearchParams,
	Link,
} from "react-router-dom";
import { UserContext } from "../context/UserContext";
import { ProfileContext } from "../context/ProfileContext";
import { KeyContext } from "../context/KeyContext";
import { SaltContext } from "../context/SaltContext";
import { useSnackbar } from "react-simple-snackbar";
import { onMessageListener } from "../components/firebase";
import { format } from "date-fns";
import {
	encrypt,
	decrypt,
	getAge,
	getImportFields,
	encryptBatch,
} from "../components/Utils";
import QRCode from "qrcode.react";
import QRCodeReader from "qrcode-reader";
import jsQR from "jsqr";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Select, { components } from "react-select";
import PageLoader from "../components/PageLoader";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Tooltip from "@mui/material/Tooltip";
import EmptyPage from "../components/EmptyPage";
import Dropdown from "react-bootstrap/Dropdown";
import Order from "../components/Order";
import Message from "../components/Message";
import MessageLoader from "../components/MessageLoader";
import Spinner from "react-bootstrap/esm/Spinner";
import AssetMember from "../components/AssetMember";
import AssetMFA from "../components/AssetMFA";
import DeletedNumber from "../components/DeletedNumber";
import TabLoader from "../components/TabLoader";
import AsyncSelect from "react-select/async";
import Papa from "papaparse";

const Group = () => {
	let { id } = useParams();
	const navigate = useNavigate();
	const tabs = [
		"inbox",
		"numbers",
		"assets",
		"members",
		"activity",
		"settings",
	];
	const forwardEmailRef = useRef(null);
	const forwardSmsRef = useRef(null);
	const forwardPushRef = useRef(null);
	const fileRef = useRef(null);
	const [user] = useContext(UserContext);
	const [profile] = useContext(ProfileContext);
	const [key] = useContext(KeyContext);
	const [salt] = useContext(SaltContext);
	const [searchParams, setSearchParams] = useSearchParams({});
	const [group, setGroup] = useState(null);
	const [tab, setTab] = useState(
		searchParams.get("tab") && tabs.includes(searchParams.get("tab"))
			? searchParams.get("tab")
			: "assets"
	);
	const [msisdnTab, setMsisdnTab] = useState("new");
	const [members, setMembers] = useState([]);
	const [assets, setAssets] = useState([]);
	const [displayAssets, setDisplayAssets] = useState([]);
	const [assetRequests, setAssetRequests] = useState([]);
	const [logs, setLogs] = useState([]);
	const [groupMfas, setGroupMfas] = useState([]);
	const [groupMsisdns, setGroupMsisdns] = useState([]);
	const [deletedMsisdns, setDeletedMsisdns] = useState([]);
	const [memberEmail, setMemberEmail] = useState("");
	const [name, setName] = useState("");
	const [nameInput, setNameInput] = useState("");
	const [msisdn, setMsisdn] = useState("");
	const [options, setOptions] = useState("");
	const [isOwner, setIsOwner] = useState(false);
	const [assetModal, setAssetModal] = useState(false);
	const [memberModal, setMemberModal] = useState(false);
	const [groupModal, setGroupModal] = useState(false);
	const [deleteModal, setDeleteModal] = useState(false);
	const [leaveModal, setLeaveModal] = useState(false);
	const [toRemove, setToRemove] = useState(null);
	const [loading, setLoading] = useState(false);
	const [actionLoading, setActionLoading] = useState(false);
	const [exportLoading, setExportLoading] = useState(false);
	const [importLoading, setImportLoading] = useState(false);
	const [editEnabled, setEditEnabled] = useState(false);
	const [numberModal, setNumberModal] = useState(false);
	const [assetSalt, setAssetSalt] = useState(null);
	const [toDeleteMsisdn, setToDeleteMsisdn] = useState(null);
	const [messages, setMessages] = useState([]);
	const [lockedMessages, setLockedMessages] = useState([]);
	const [inboxLoading, setInboxLoading] = useState(false);
	const [tabLoading, setTabLoading] = useState(true);
	const [msisdnLoading, setMsisdnLoading] = useState(true);
	const [meta, setMeta] = useState(null);
	const [page, setPage] = useState(1);
	const [orders, setOrders] = useState([]);
	const [activeLog, setActiveLog] = useState(null);
	const [assetInfo, setAssetInfo] = useState(null);
	const [sharedTo, setSharedTo] = useState([]);
	const [assetRequestInfo, setAssetRequestInfo] = useState(null);
	const [acceptAsset, setAcceptAsset] = useState(null);
	const [requestAssetId, setRequestAssetId] = useState(null);
	const [rejectAssetLoading, setRejectAssetLoading] = useState(false);

	const [forwardEmail, setForwardEmail] = useState("");
	const [forwardEmailModal, setForwardEmailModal] = useState(false);
	const [removeEmailModal, setRemoveEmailModal] = useState(false);

	const [forwardPush, setForwardPush] = useState("");
	const [forwardPushModal, setForwardPushModal] = useState(false);
	const [removePushModal, setRemovePushModal] = useState(false);

	const [countryCode, setCountryCode] = useState("");
	const [areaCode, setAreaCode] = useState("");
	const [phoneNumber, setPhoneNumber] = useState("");
	const [forwardSms, setForwardSms] = useState("");
	const [forwardSmsModal, setForwardSmsModal] = useState(false);
	const [removeSmsModal, setRemoveSmsModal] = useState(false);
	const [subscribeModal, setSubscribeModal] = useState(false);
	const [subscribeError, setSubscribeError] = useState("");

	const [assetName, setAssetName] = useState("");
	const [assetType, setAssetType] = useState("Login");
	const [assetUrl, setAssetUrl] = useState("");
	const [assetNotes, setAssetNotes] = useState("");
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [assetCode, setAssetCode] = useState("");
	const [assetMsisdn, setAssetMsisdn] = useState("");
	const [cardNo, setCardNo] = useState("");
	const [cardExpiry, setCardExpiry] = useState("");
	const [cardCode, setCardCode] = useState("");
	const [cardName, setCardName] = useState("");
	const [apiKey, setApiKey] = useState("");
	const [apiSecret, setApiSecret] = useState("");
	const [docs, setDocs] = useState("");
	const [street, setStreet] = useState("");
	const [city, setCity] = useState("");
	const [province, setProvince] = useState("");
	const [country, setCountry] = useState("");
	const [postalCode, setPostalCode] = useState("");
	const [wifiEncryption, setWifiEncryption] = useState("WPA2");
	const [wifiName, setWifiName] = useState("");
	const [wifiPassword, setWifiPassword] = useState("");
	const [wifiIsHidden, setWifiIsHidden] = useState(false);
	const [privateKey, setPrivateKey] = useState("");
	const [seedPhrase, setSeedPhrase] = useState("");
	const [showWifiPassword, setShowWifiPassword] = useState(false);
	const [assetLoginType, setAssetLoginType] = useState("username");
	const [ssoAsset, setSSOAsset] = useState(null);

	const [assetLogs, setAssetLogs] = useState([]);
	const [activeAssetLog, setActiveAssetLog] = useState(null);

	const [contactName, setContactName] = useState("");
	const [contactEmail, setContactEmail] = useState("");
	const [contactMsisdn, setContactMsisdn] = useState("");

	const [showPassword, setShowPassword] = useState(false);
	const [showAssetCode, setShowAssetCode] = useState(false);
	const [assetInfoModal, setAssetInfoModal] = useState(false);
	const [assetLoading, setAssetLoading] = useState(false);
	const [assetTab, setAssetTab] = useState("details");
	const [canEdit, setCanEdit] = useState(false);
	const [deleteAssetId, setDeleteAssetId] = useState(null);

	const [assetRequestModal, setAssetRequestModal] = useState(false);
	const [assetRequestName, setAssetRequestName] = useState("");
	const [assetRequestType, setAssetRequestType] = useState("Login");
	const [assetRequestDesc, setAssetRequestDesc] = useState("");
	const [allowEdit, setAllowEdit] = useState(false);
	const [expiration, setExpiration] = useState("15");
	const [shareAllowEdit, setShareAllowEdit] = useState(false);
	const [shareExpiration, setShareExpiration] = useState("15");
	const [exportModal, setExportModal] = useState(false);
	const [exportType, setExportType] = useState("json");
	const [importModal, setImportModal] = useState(false);
	const [provider, setProvider] = useState("Apple");
	const [importFile, setImportFile] = useState(null);

	const [linkLoading, setLinkLoading] = useState(false);
	const [assetLinks, setAssetLinks] = useState([]);

	const [service, setService] = useState("");
	const [account, setAccount] = useState("");
	const [token, setToken] = useState("");
	const [assetErrors, setAssetErrors] = useState([]);
	const [shareInput, setShareInput] = useState("");
	const [shareTo, setShareTo] = useState([]);
	const [linkForm, setLinkForm] = useState(false);
	const [linkEmail, setLinkEmail] = useState("");

	const [search, setSearch] = useState(searchParams.get("q") || "");
	const [assetCopyId, setAssetCopyId] = useState(null);

	const [defaultSnackbar] = useSnackbar();

	const [dangerSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#d81e5b",
			color: "#fff",
		},
	});

	const requiredFields = {
		Login: {
			username: [
				{ key: "assetName", value: assetName },
				{ key: "username", value: username },
				{ key: "password", value: password },
			],
			sso: [
				{ key: "assetName", value: assetName },
				{ key: "ssoAsset", value: ssoAsset },
			],
		},
		MFA: [
			{ key: "assetName", value: assetName },
			{ key: "assetCode", value: assetCode },
		],
		Card: [
			{ key: "assetName", value: assetName },
			{ key: "cardNo", value: cardNo },
			{ key: "cardExpiry", value: cardExpiry },
			{ key: "cardCode", value: cardCode },
			{ key: "cardName", value: cardName },
		],
		Address: [
			{ key: "assetName", value: assetName },
			{ key: "street", value: street },
			{ key: "city", value: city },
			{ key: "province", value: province },
			{ key: "country", value: country },
			{ key: "postalCode", value: postalCode },
		],
		API: [
			{ key: "assetName", value: assetName },
			{ key: "apiKey", value: apiKey },
		],
		Contact: [{ key: "assetName", value: assetName }],
		Wifi: [
			{ key: "assetName", value: assetName },
			{ key: "wifiName", value: wifiName },
			{ key: "wifiPassword", value: wifiPassword },
			{ key: "wifiEnwifiEncryption", value: wifiEncryption },
			{ key: "wifiIsHidden", value: wifiIsHidden },
		],
		Note: [
			{ key: "assetName", value: assetName },
			{ key: "assetNotes", value: assetNotes },
		],
	};

	const countryOptions = [
		{
			value: "US",
			label: "(+1) United States - $6/month",
			img: "https://flagcdn.com/h120/us.png",
		},
		{
			value: "CA",
			label: "(+1) Canada - $6/month",
			img: "https://flagcdn.com/h120/ca.png",
		},
	];

	const smsOptions = [
		{
			value: "+1",
			label: "US (+1)",
			img: "https://flagcdn.com/h120/us.png",
		},
		{
			value: "+1",
			label: "CA (+1)",
			img: "https://flagcdn.com/h120/ca.png",
		},
	];

	const assetOptionToggle = React.forwardRef(({ children, onClick }, ref) => (
		<a
			href=""
			ref={ref}
			onClick={(e) => {
				e.preventDefault();
				onClick(e);
			}}
		>
			{children}
		</a>
	));

	const getData = async () => {
		setLoading(true);
		const requestOptions = {
			method: "GET",
			headers: {
				"X-Auth-Token": user ? user.session_token : "",
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/groups/${id}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar("Something went wrong.");
		} else {
			document.title = `${data.group.name} | SMSafe`;
			setGroup(data.group);
			setName(data.group.name);
			setMembers(data.group_users);
			setGroupMsisdns(data.active_msisdns);
			setNameInput(data.group.name);
			if (
				data.group.group_users.filter(
					(membersFilter) =>
						membersFilter.user.username === user.username &&
						membersFilter.is_owner === true
				).length !== 0
			) {
				setIsOwner(true);
			} else {
				setIsOwner(false);
			}
		}
		setLoading(false);
	};

	const getMessages = async () => {
		const requestOptions = {
			method: "GET",
			headers: {
				"X-Auth-Token": user ? user.session_token : "",
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/groups/${id}/messages${window.location.search}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			console.log("Something went wrong.");
		} else {
			setMessages(data.messages);
			setLockedMessages(data.locked_messages);
			setMeta(data.meta);
		}
		setTabLoading(false);
	};

	const requestAccess = async () => {
		setActionLoading(true);
		const requestOptions = {
			method: "POST",
			headers: {
				"X-Auth-Token": user ? user.session_token : "",
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/assets/${requestAssetId}/access/requests`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar(data.msg);
		} else {
			defaultSnackbar(data.msg);
		}
		setRequestAssetId(null);
		setActionLoading(false);
	};

	const logCopy = async (msisdn) => {
		const requestOptions = {
			method: "POST",
			headers: {
				"X-Auth-Token": user ? user.session_token : "",
			},
			body: JSON.stringify({
				msisdn: msisdn,
				group_id: id,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/v-msisdn/copy`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			console.log("Something went wrong.");
		}
	};

	const getNumbers = async () => {
		const requestOptions = {
			method: "GET",
			headers: {
				"X-Auth-Token": user ? user.session_token : "",
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/groups/${id}/v-msisdns${window.location.search}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			console.log("Something went wrong.");
		} else {
			setGroupMsisdns(data.group_v_msisdns);
			setOrders(data.orders);
		}
		setTabLoading(false);
	};

	const getDeletedNumbers = async () => {
		setMsisdnLoading(true);
		const requestOptions = {
			method: "GET",
			headers: {
				"X-Auth-Token": user ? user.session_token : "",
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/groups/${id}/v-msisdns/deleted`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			console.log("Something went wrong.");
		} else {
			setDeletedMsisdns(data.deleted_msisdns);
		}
		setMsisdnLoading(false);
	};

	const getMembers = async () => {
		const requestOptions = {
			method: "GET",
			headers: {
				"X-Auth-Token": user ? user.session_token : "",
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/groups/${id}/members${window.location.search}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			console.log("Something went wrong.");
		} else {
			setMembers(data.group_members);
		}
		setTabLoading(false);
	};

	const getAssets = async () => {
		setTabLoading(true);
		const requestOptions = {
			method: "GET",
			headers: {
				"X-Auth-Token": user ? user.session_token : "",
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/groups/${id}/assets`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			console.log("Something went wrong.");
		} else {
			const keep = [];

			data.parent_assets.forEach((parentAsset) => {
				if (
					data.asset_copies.filter(
						(assetCopy) => assetCopy.asset_id === parentAsset.id
					).length === 0
				) {
					keep.push(parentAsset);
				}
			});

			decryptAssetData([...data.asset_copies, ...keep]);

			setAssetRequests(data.asset_requests);
		}
		setTabLoading(false);
	};

	const getLogs = async () => {
		const requestOptions = {
			method: "GET",
			headers: {
				"X-Auth-Token": user ? user.session_token : "",
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/groups/${id}/logs${window.location.search}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			console.log("Something went wrong.");
		} else {
			setLogs(data.logs);
		}
		setTabLoading(false);
	};

	const saveForwardChannel = async (e, type, value) => {
		e.preventDefault();
		setActionLoading(true);
		const requestOptions = {
			method: "POST",
			headers: {
				"X-Auth-Token": user ? user.session_token : "",
			},
			body: JSON.stringify({
				type_: type,
				value: value,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/v-msisdn/forwarding/${id}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar(data.msg);
		} else {
			if (type === "email") {
				setForwardEmailModal(false);
			} else if (type === "push") {
				setForwardPushModal(false);
			} else {
				setForwardSmsModal(false);
			}

			defaultSnackbar(data.msg);
			getData();
		}
		setActionLoading(false);
	};

	const removeForwardChannel = async (e, type) => {
		e.preventDefault();
		setActionLoading(true);
		const requestOptions = {
			method: "DELETE",
			headers: {
				"X-Auth-Token": user ? user.session_token : "",
			},
			body: JSON.stringify({
				type_: type,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/v-msisdn/forwarding/${id}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar(data.msg);
		} else {
			if (type === "email") {
				setRemoveEmailModal(false);
			} else if (type === "push") {
				setRemovePushModal(false);
			} else {
				setRemoveSmsModal(false);
			}
			defaultSnackbar(data.msg);
			getData();
		}
		setActionLoading(false);
	};

	const inviteMember = async (e) => {
		e.preventDefault();
		setActionLoading(true);
		const requestOptions = {
			method: "POST",
			headers: {
				"X-Auth-Token": user ? user.session_token : "",
			},
			body: JSON.stringify({
				group_id: id,
				email: memberEmail,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/group-users`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			if (data.error === "subscription") {
				setSubscribeError(data.msg);
				setSubscribeModal(true);
				setMemberEmail("");
				setMemberModal(false);
			} else {
				dangerSnackbar("Something went wrong.");
			}
		} else {
			setMemberEmail("");
			setMemberModal(false);
			defaultSnackbar(data.msg);
			setMembers(data.group_users);
		}
		setActionLoading(false);
	};

	const getOptions = async () => {
		const requestOptions = {
			method: "GET",
			headers: {
				"X-Auth-Token": user ? user.session_token : "",
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/v-msisdn?all=true`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			console.log("Something went wrong.");
		} else {
			setOptions(
				data.data.map((number) => ({
					value: number.v_msisdn.id,
					label: number.v_msisdn.msisdn,
					img: number.v_msisdn.flag_uri,
				}))
			);
		}
	};

	const { Option } = components;
	const IconOption = (props) => (
		<Option {...props}>
			<div className="flex-row-left">
				<img
					src={props.data.img}
					alt={props.data.label}
					className="country-icon"
				/>
				<span className="text-sm">{props.data.label}</span>
			</div>
		</Option>
	);

	const editGroup = async (e) => {
		e.preventDefault();
		setActionLoading(true);
		const requestOptions = {
			method: "POST",
			headers: {
				"X-Auth-Token": user ? user.session_token : "",
			},
			body: JSON.stringify({
				name: nameInput,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/groups/${id}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar(data.msg);
		} else {
			setName(nameInput);
			defaultSnackbar(data.msg);
		}
		setActionLoading(false);
	};

	const deleteGroup = async (e) => {
		e.preventDefault();
		setActionLoading(true);
		const requestOptions = {
			method: "DELETE",
			headers: {
				"X-Auth-Token": user ? user.session_token : "",
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/groups/${id}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar(data.msg);
		} else {
			setDeleteModal(false);
			defaultSnackbar(data.msg);
			navigate("/safes");
		}
		setActionLoading(false);
	};

	const leaveGroup = async (e) => {
		e.preventDefault();
		setActionLoading(true);
		const requestOptions = {
			method: "POST",
			headers: {
				"X-Auth-Token": user ? user.session_token : "",
			},
			body: JSON.stringify({
				group_id: id,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/me/groups/leave`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar(data.msg);
		} else {
			setDeleteModal(false);
			defaultSnackbar(data.msg);
			navigate("/safes");
		}
		setActionLoading(false);
	};

	const removeMember = async (e) => {
		e.preventDefault();
		setActionLoading(true);
		const requestOptions = {
			method: "DELETE",
			headers: {
				"X-Auth-Token": user ? user.session_token : "",
			},
			body: JSON.stringify({
				group_id: id,
				user_id: toRemove.id,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/group-users`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar(data.msg);
		} else {
			setToRemove(null);
			defaultSnackbar(data.msg);
			setMembers(data.group_users);
		}
		setActionLoading(false);
	};

	const addNumber = async (e) => {
		e.preventDefault();
		setActionLoading(true);
		const requestOptions = {
			method: "POST",
			headers: {
				"X-Auth-Token": user ? user.session_token : "",
			},
			body: JSON.stringify({
				local_code: countryCode.value,
				group_id: id,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/v-msisdn`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			if (data.error === "subscription") {
				setSubscribeError(data.msg);
				setSubscribeModal(true);
			} else {
				dangerSnackbar("Something went wrong.");
			}
		} else {
			setCountryCode("");
			setOrders(data.group_orders);
		}
		setNumberModal(false);
		setActionLoading(false);
	};

	const encryptAssetData = (callback) => {
		const encryptedData = {};
		const assetPassword = decrypt(key, profile.user.email, salt);

		encryptedData.assetNotes = encrypt(assetNotes, assetPassword, salt);
		encryptedData.username = encrypt(username, assetPassword, salt);
		encryptedData.password = encrypt(password, assetPassword, salt);
		encryptedData.assetMsisdn = encrypt(assetMsisdn, assetPassword, salt);
		encryptedData.cardNo = encrypt(cardNo, assetPassword, salt);
		encryptedData.cardExpiry = encrypt(cardExpiry, assetPassword, salt);
		encryptedData.cardCode = encrypt(cardCode, assetPassword, salt);
		encryptedData.cardName = encrypt(cardName, assetPassword, salt);
		encryptedData.apiKey = encrypt(apiKey, assetPassword, salt);
		encryptedData.apiSecret = encrypt(apiSecret, assetPassword, salt);
		encryptedData.docs = encrypt(docs, assetPassword, salt);
		encryptedData.street = encrypt(street, assetPassword, salt);
		encryptedData.city = encrypt(city, assetPassword, salt);
		encryptedData.province = encrypt(province, assetPassword, salt);
		encryptedData.country = encrypt(country, assetPassword, salt);
		encryptedData.postalCode = encrypt(postalCode, assetPassword, salt);
		encryptedData.contactName = encrypt(contactName, assetPassword, salt);
		encryptedData.contactEmail = encrypt(contactEmail, assetPassword, salt);
		encryptedData.contactMsisdn = encrypt(contactMsisdn, assetPassword, salt);
		encryptedData.wifiName = encrypt(wifiName, assetPassword, salt);
		encryptedData.wifiPassword = encrypt(wifiPassword, assetPassword, salt);
		encryptedData.wifiEncryption = encrypt(wifiEncryption, assetPassword, salt);
		encryptedData.privateKey = encrypt(privateKey, assetPassword, salt);
		encryptedData.seedPhrase = encrypt(seedPhrase, assetPassword, salt);

		callback(encryptedData);
	};

	const addAsset = async (encryptedData) => {
		var errors = [];

		const requestOptions = {
			method: "POST",
			headers: {
				"X-Auth-Token": user ? user.session_token : "",
			},
			body: JSON.stringify({
				name: assetName,
				type: assetType,
				url: assetUrl,
				login_type: assetLoginType,
				sso_id: ssoAsset ? ssoAsset.value : null,
				notes: encryptedData.assetNotes,
				username: encryptedData.username,
				password: encryptedData.password,
				msisdn: encryptedData.assetMsisdn,
				card_no: encryptedData.cardNo,
				expiration: encryptedData.cardExpiry,
				cvv: encryptedData.cardCode,
				card_name: encryptedData.cardName,
				api_key: encryptedData.apiKey,
				api_secret: encryptedData.apiSecret,
				documentation: encryptedData.docs,
				street: encryptedData.street,
				city: encryptedData.city,
				province: encryptedData.province,
				country: encryptedData.country,
				postal_code: encryptedData.postalCode,
				contact_name: encryptedData.contactName,
				contact_email: encryptedData.contactEmail,
				contact_msisdn: encryptedData.contactMsisdn,
				wifi_name: encryptedData.wifiName,
				wifi_password: encryptedData.wifiPassword,
				wifi_encryption: encryptedData.wifiEncryption,
				private_key: encryptedData.privateKey,
				seed_phrase: encryptedData.seedPhrase,
				token: assetCode,
				wifi_is_hidden: wifiIsHidden,
				accepted_request_id: acceptAsset?.id || "",
				allow_edit: allowEdit,
				access_expiration: expiration,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/groups/${id}/assets`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar(data.msg);
			errors.push(data.error);
			setAssetErrors(errors);
		} else {
			defaultSnackbar(data.msg);
			closeAssetModal();
			getAssets();
		}
		setAssetSalt(null);
		setActionLoading(false);
	};

	const unlockAsset = async (encryptedData) => {
		const requestOptions = {
			method: "POST",
			headers: {
				"X-Auth-Token": user ? user.session_token : "",
			},
			body: JSON.stringify({
				notes: encryptedData.assetNotes,
				username: encryptedData.username,
				password: encryptedData.password,
				msisdn: encryptedData.assetMsisdn,
				card_no: encryptedData.cardNo,
				expiration: encryptedData.cardExpiry,
				cvv: encryptedData.cardCode,
				card_name: encryptedData.cardName,
				api_key: encryptedData.apiKey,
				api_secret: encryptedData.apiSecret,
				documentation: encryptedData.docs,
				street: encryptedData.street,
				city: encryptedData.city,
				province: encryptedData.province,
				country: encryptedData.country,
				postal_code: encryptedData.postalCode,
				contact_name: encryptedData.contactName,
				contact_email: encryptedData.contactEmail,
				contact_msisdn: encryptedData.contactMsisdn,
				wifi_name: encryptedData.wifiName,
				wifi_password: encryptedData.wifiPassword,
				wifi_encryption: encryptedData.wifiEncryption,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/assets/${assetCopyId}/unlock`,
			requestOptions
		);

		if (!response.ok) {
			closeAssetModal();
			getAssets();
			defaultSnackbar("Assets refreshed.");
		} else {
			setAssetCopyId(null);
		}
	};

	const handleAssetSubmit = (e) => {
		e.preventDefault();
		setActionLoading(true);
		var newErrors = [];

		if (assetType === "Login") {
			requiredFields[assetType][assetLoginType].map((field) => {
				if (field.value === "" || field.value === null) {
					newErrors.push(field.key);
				}
			});
		} else {
			requiredFields[assetType].map((field) => {
				if (field.value === "" || field.value === null) {
					newErrors.push(field.key);
				}
			});
		}

		if (newErrors.length !== 0) {
			dangerSnackbar("Please fill out all required fields.");
			setActionLoading(false);
			setAssetErrors(newErrors);
			return;
		}
		encryptAssetData(addAsset);
	};

	const decryptAssetData = async (hashedAssets) => {
		const assetPassword = decrypt(key, profile.user.email, salt);

		const decryptedAssets = await Promise.all(
			hashedAssets.map(async (hashedAsset) => {
				if (hashedAsset.asset_id && !hashedAsset.unlocked) {
					return { ...hashedAsset }; // Return directly for unlocked assets
				}

				return {
					...hashedAsset,
					sso: hashedAsset.sso
						? {
								id: hashedAsset.sso.id,
								name: hashedAsset.sso.name,
								url: hashedAsset.sso.url,
								username: decrypt(
									hashedAsset.sso.username,
									assetPassword,
									salt
								),
								password: decrypt(
									hashedAsset.sso.password,
									assetPassword,
									salt
								),
								msisdn: decrypt(hashedAsset.sso.msisdn, assetPassword, salt),
								notes: decrypt(hashedAsset.sso.notes, assetPassword, salt),
								mfa_token: hashedAsset.sso.mfa_token,
						  }
						: null,
					username: decrypt(hashedAsset.username, assetPassword, salt),
					password: decrypt(hashedAsset.password, assetPassword, salt),
					msisdn: decrypt(hashedAsset.msisdn, assetPassword, salt),
					card_no: decrypt(hashedAsset.card_no, assetPassword, salt),
					expiration: decrypt(hashedAsset.expiration, assetPassword, salt),
					cvv: decrypt(hashedAsset.cvv, assetPassword, salt),
					card_name: decrypt(hashedAsset.card_name, assetPassword, salt),
					api_key: decrypt(hashedAsset.api_key, assetPassword, salt),
					api_secret: decrypt(hashedAsset.api_secret, assetPassword, salt),
					documentation: decrypt(
						hashedAsset.documentation,
						assetPassword,
						salt
					),
					street: decrypt(hashedAsset.street, assetPassword, salt),
					city: decrypt(hashedAsset.city, assetPassword, salt),
					province: decrypt(hashedAsset.province, assetPassword, salt),
					country: decrypt(hashedAsset.country, assetPassword, salt),
					postal_code: decrypt(hashedAsset.postal_code, assetPassword, salt),
					contact_name: decrypt(hashedAsset.contact_name, assetPassword, salt),
					contact_email: decrypt(
						hashedAsset.contact_email,
						assetPassword,
						salt
					),
					contact_msisdn: decrypt(
						hashedAsset.contact_msisdn,
						assetPassword,
						salt
					),
					wifi_encryption: decrypt(
						hashedAsset.wifi_encryption,
						assetPassword,
						salt
					),
					wifi_name: decrypt(hashedAsset.wifi_name, assetPassword, salt),
					wifi_password: decrypt(
						hashedAsset.wifi_password,
						assetPassword,
						salt
					),
					notes: decrypt(hashedAsset.notes, assetPassword, salt),
				};
			})
		);

		setAssets(decryptedAssets);
		filterAssets(decryptedAssets);
	};

	const getImportSalt = async (items) => {
		setImportLoading(true);
		const requestOptions = {
			method: "GET",
			headers: {
				"X-Auth-Token": user ? user.session_token : "",
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/me/salt`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar(data.msg);
			setImportLoading(false);
		} else {
			processImportItems(data.salt, items);
		}
	};

	const handleAssetEdit = (e) => {
		e.preventDefault();
		setActionLoading(true);
		var newErrors = [];

		if (assetType === "Login") {
			requiredFields[assetType][assetLoginType].map((field) => {
				if (field.value === "" || field.value === null) {
					newErrors.push(field.key);
				}
			});
		} else {
			requiredFields[assetType].map((field) => {
				if (field.value === "" || field.value === null) {
					newErrors.push(field.key);
				}
			});
		}

		if (newErrors.length !== 0) {
			dangerSnackbar("Please fill out all required fields.");
			setActionLoading(false);
			setAssetErrors(newErrors);
			return;
		}
		encryptAssetData(editAsset);
	};

	const requestAsset = async (e) => {
		e.preventDefault();
		setActionLoading(true);
		const requestOptions = {
			method: "POST",
			headers: {
				"X-Auth-Token": user ? user.session_token : "",
			},
			body: JSON.stringify({
				name: assetRequestName,
				type: assetRequestType,
				description: assetRequestDesc,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/groups/${id}/asset-requests`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar("Something went wrong.");
		} else {
			defaultSnackbar(data.msg);
			getAssets();
		}
		closeAssetRequestModal();
		setActionLoading(false);
	};

	const rejectAssetRequest = async () => {
		setActionLoading(true);
		setRejectAssetLoading(true);
		const requestOptions = {
			method: "POST",
			headers: {
				"X-Auth-Token": user ? user.session_token : "",
			},
			body: JSON.stringify({
				name: assetRequestName,
				type: assetRequestType,
				description: assetRequestDesc,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/asset-requests/${assetRequestInfo.id}/reject`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar("Something went wrong.");
		} else {
			defaultSnackbar(data.msg);
			getAssets();
		}
		setAssetRequestInfo(null);
		setActionLoading(false);
		setRejectAssetLoading(false);
	};

	const editAsset = async (encryptedData) => {
		var errors = [];
		const requestOptions = {
			method: "POST",
			headers: {
				"X-Auth-Token": user ? user.session_token : "",
			},
			body: JSON.stringify({
				hashed: {
					name: assetName,
					type: assetType,
					url: assetUrl,
					login_type: assetLoginType,
					sso_id: ssoAsset ? ssoAsset.value : null,
					notes: encryptedData.assetNotes,
					username: encryptedData.username,
					password: encryptedData.password,
					msisdn: encryptedData.assetMsisdn,
					card_no: encryptedData.cardNo,
					expiration: encryptedData.cardExpiry,
					cvv: encryptedData.cardCode,
					card_name: encryptedData.cardName,
					api_key: encryptedData.apiKey,
					api_secret: encryptedData.apiSecret,
					documentation: encryptedData.docs,
					street: encryptedData.street,
					city: encryptedData.city,
					province: encryptedData.province,
					country: encryptedData.country,
					postal_code: encryptedData.postalCode,
					contact_name: encryptedData.contactName,
					contact_email: encryptedData.contactEmail,
					contact_msisdn: encryptedData.contactMsisdn,
					wifi_name: encryptedData.wifiName,
					wifi_password: encryptedData.wifiPassword,
					wifi_encryption: encryptedData.wifiEncryption,
					wifi_is_hidden: wifiIsHidden,
					token: assetCode,
				},
				raw:
					sharedTo?.length !== 0
						? {
								name: assetName,
								type: assetType,
								url: assetUrl,
								login_type: assetLoginType,
								sso_id: ssoAsset ? ssoAsset.value : null,
								notes: assetNotes,
								username: username,
								password: password,
								msisdn: assetMsisdn,
								card_no: cardNo,
								expiration: cardExpiry,
								cvv: cardCode,
								card_name: cardName,
								api_key: apiKey,
								api_secret: apiSecret,
								documentation: docs,
								street: street,
								city: city,
								province: province,
								country: country,
								postal_code: postalCode,
								contact_name: contactName,
								contact_email: contactEmail,
								contact_msisdn: contactMsisdn,
								wifi_name: wifiName,
								wifi_password: wifiPassword,
								wifi_encryption: wifiEncryption,
								wifi_is_hidden: wifiIsHidden,
								token: assetCode,
								sso: ssoAsset
									? {
											id: assets.filter(
												(asset) => asset.id === ssoAsset.value
											)[0].id,
											username: assets.filter(
												(asset) => asset.id === ssoAsset.value
											)[0].username,
											password: assets.filter(
												(asset) => asset.id === ssoAsset.value
											)[0].password,
											msisdn: assets.filter(
												(asset) => asset.id === ssoAsset.value
											)[0].msisdn,
											notes: assets.filter(
												(asset) => asset.id === ssoAsset.value
											)[0].notes,
									  }
									: null,
						  }
						: null,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/assets/${
				assetInfo.asset_id ? assetInfo.asset_id : assetInfo.id
			}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar(data.msg);
			errors.push(data.error);
			setAssetErrors(errors);
		} else {
			defaultSnackbar(data.msg);
			getAssets();
			closeAssetModal();
		}
		setActionLoading(false);
	};

	const deleteAsset = async () => {
		setActionLoading(true);
		const requestOptions = {
			method: "DELETE",
			headers: {
				"X-Auth-Token": user ? user.session_token : "",
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/assets/${deleteAssetId}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar("Something went wrong.");
		} else {
			defaultSnackbar(data.msg);
			getAssets();
			closeAssetModal();
		}
		setDeleteAssetId(null);
		setActionLoading(false);
	};

	const handleFileInputChange = (e) => {
		const file = e.target.files[0];
		if (!file) return;
		const qrCodeReader = new QRCodeReader();

		// Read the image file using FileReader
		const reader = new FileReader();
		reader.onload = () => {
			const img = new Image();
			img.src = reader.result;
			img.onload = () => {
				// Decode the QR code from the image
				const canvas = document.createElement("canvas");
				const context = canvas.getContext("2d");
				canvas.width = img.width;
				canvas.height = img.height;
				context.drawImage(img, 0, 0);

				const imageData = context.getImageData(
					0,
					0,
					canvas.width,
					canvas.height
				);

				// Decode the QR code using jsQR
				const code = jsQR(imageData.data, imageData.width, imageData.height);

				if (code) {
					const params = code.data.split("&");
					let secret = "";
					params.forEach((param) => {
						if (param.includes("secret=")) {
							secret = param.split("=")[1];
						}
					});
					setAssetCode(secret);
				} else {
					dangerSnackbar("QR code not detected.");
				}
			};
		};
		reader.readAsDataURL(file);
	};

	const deleteMsisdn = async () => {
		setActionLoading(true);
		const requestOptions = {
			method: "DELETE",
			headers: {
				"X-Auth-Token": user ? user.session_token : "",
			},
			body: JSON.stringify({
				group_id: id,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/v-msisdn/${toDeleteMsisdn}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar(data.msg);
		} else {
			defaultSnackbar(data.msg);
			getNumbers();
		}
		setToDeleteMsisdn(null);
		setActionLoading(false);
	};

	const confirmAssetAccess = async (asset_id) => {
		const requestOptions = {
			method: "GET",
			headers: {
				"X-Auth-Token": user ? user.session_token : "",
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/assets/${asset_id}/access`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			return false;
		} else {
			return true;
		}
	};

	const handleAsset = async (asset) => {
		setAssetLoading(true);
		setAssetInfoModal(true);

		const requestOptions = {
			method: "GET",
			headers: {
				"X-Auth-Token": user ? user.session_token : "",
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/assets/${
				asset.asset_id ? asset.asset_id : asset.id
			}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			closeAssetModal();
			getAssets();
			defaultSnackbar("Assets refreshed.");
		} else {
			setAssetInfo(asset);
			setAssetName(asset.name);
			setAssetType(asset.type_);
			setAssetUrl(asset.url);
			setUsername(asset.username);
			setPassword(asset.password);
			setAssetMsisdn(asset.msisdn);
			setCardNo(asset.card_no);
			setCardExpiry(asset.expiration);
			setCardCode(asset.cvv);
			setCardName(asset.card_name);
			setApiKey(asset.api_key);
			setApiSecret(asset.api_secret);
			setDocs(asset.documentation);
			setStreet(asset.street);
			setCity(asset.city);
			setProvince(asset.province);
			setCountry(asset.country);
			setPostalCode(asset.postal_code);
			setContactName(asset.contact_name);
			setContactEmail(asset.contact_email);
			setContactMsisdn(asset.contact_msisdn);
			setWifiEncryption(asset.wifi_encryption);
			setWifiName(asset.wifi_name);
			setWifiPassword(asset.wifi_password);
			setAssetLoginType(asset.login_type);
			setSSOAsset(
				asset.sso
					? {
							value: asset.sso.id,
							label: `${asset.sso.name} ${
								asset.sso.username ? "(" + asset.sso.username + ")" : ""
							}`,
					  }
					: null
			);

			if (asset.asset_id && asset.unlocked === false) {
				setAssetCopyId(asset.id);
			}

			setAssetCode(asset.mfa_token?.token || "");
			setWifiIsHidden(asset.wifi_is_hidden);

			setAssetLinks(asset.links || []);
			setAssetLogs(data.asset.logs);
			setCanEdit(data.can_edit);
			setSharedTo(data.asset.shared_to);
		}
		setAssetLoading(false);
	};

	useEffect(() => {
		if (assetCopyId) {
			encryptAssetData(unlockAsset);
		}
	}, [assetCopyId]);

	const decryptAsset = (asset) => {
		const assetPassword = decrypt(key, profile.user.email, salt);

		setUsername(decrypt(asset.username, assetPassword, salt));
		setPassword(decrypt(asset.password, assetPassword, salt));
		setAssetMsisdn(decrypt(asset.msisdn, assetPassword, salt));
		setCardNo(decrypt(asset.card_no, assetPassword, salt));
		setCardExpiry(decrypt(asset.expiration, assetPassword, salt));
		setCardCode(decrypt(asset.cvv, assetPassword, salt));
		setCardName(decrypt(asset.card_name, assetPassword, salt));
		setApiKey(decrypt(asset.api_key, assetPassword, salt));
		setApiSecret(decrypt(asset.api_secret, assetPassword, salt));
		setDocs(decrypt(asset.documentation, assetPassword, salt));
		setStreet(decrypt(asset.street, assetPassword, salt));
		setCity(decrypt(asset.city, assetPassword, salt));
		setProvince(decrypt(asset.province, assetPassword, salt));
		setCountry(decrypt(asset.country, assetPassword, salt));
		setPostalCode(decrypt(asset.postal_code, assetPassword, salt));
		setContactName(decrypt(asset.contact_name, assetPassword, salt));
		setContactEmail(decrypt(asset.contact_email, assetPassword, salt));
		setContactMsisdn(decrypt(asset.contact_msisdn, assetPassword, salt));
		setWifiEncryption(decrypt(asset.wifi_encryption, assetPassword, salt));
		setWifiName(decrypt(asset.wifi_name, assetPassword, salt));
		setWifiPassword(decrypt(asset.wifi_password, assetPassword, salt));
		setAssetNotes(decrypt(asset.notes, assetPassword, salt));
		setPrivateKey(decrypt(asset.private_key, assetPassword, salt));
		setSeedPhrase(decrypt(asset.seed_phrase, assetPassword, salt));
	};

	const closeAssetModal = () => {
		setAssetModal(false);
		setAssetInfoModal(null);
		setAssetInfo(null);
		setAssetName("");
		setAssetType("Login");
		setAssetUrl("");
		setUsername("");
		setPassword("");
		setAssetCode("");
		setCardNo("");
		setCardExpiry("");
		setCardCode("");
		setCardName("");
		setApiKey("");
		setApiSecret("");
		setDocs("");
		setAssetNotes("");
		setStreet("");
		setCity("");
		setProvince("");
		setCountry("");
		setPostalCode("");
		setContactName("");
		setContactEmail("");
		setContactMsisdn("");
		setShowAssetCode(false);
		setShowPassword(false);
		setAssetTab("details");
		setCanEdit(false);
		setAcceptAsset(null);
		setAssetErrors([]);
		setShareTo([]);
		setShareInput("");
		setAssetLinks([]);
		setWifiEncryption("WPA2");
		setWifiName("");
		setWifiPassword("");
		setPrivateKey("");
		setSeedPhrase("");
		setWifiIsHidden(false);
		setShowWifiPassword(false);
		setAssetLoginType("username");
		setSSOAsset(null);
		setExpiration("15");
		setShareExpiration("15");
		setAssetLogs([]);
	};

	const closeAssetRequestModal = () => {
		setAssetRequestModal(false);
		setAssetRequestName("");
		setAssetRequestType("Login");
		setAssetRequestDesc("");
	};

	const generatePassword = () => {
		const lowercaseLetters = "abcdefghijklmnopqrstuvwxyz";
		const uppercaseLetters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
		const numbers = "0123456789";
		const specialCharacters = '!@#$%&*()_"';

		const allCharacters =
			lowercaseLetters + uppercaseLetters + numbers + specialCharacters;

		let password = "";
		for (let i = 0; i < 16; i++) {
			// generating a 12-character password
			const randomIndex = Math.floor(Math.random() * allCharacters.length);
			password += allCharacters[randomIndex];
		}

		setPassword(password);
		setShowPassword(true);
	};

	const handleCardNoChange = (e) => {
		let formattedCardNo = e.target.value.replace(/\s/g, ""); // Remove any existing spaces
		formattedCardNo = formattedCardNo.replace(/(\d{4})/g, "$1 ").trim(); // Add space after every 4 characters

		setCardNo(formattedCardNo);
	};

	const handleCardExpiryChange = (e) => {
		let formattedCardExpiry = e.target.value.replace(/\s/g, ""); // Remove any existing spaces
		formattedCardExpiry = formattedCardExpiry
			.replace(/^(\d{2})(\d{1,2})$/, "$1/$2") // Add '/' after first 2 characters if not already added
			.replace(/^(\d{2})\/(\d{2})$/, "$1/$2"); // Add '/' after next 2 characters if not already added

		setCardExpiry(formattedCardExpiry);
	};

	const handleAssetTypeChange = (type) => {
		setAssetType(type);
		setAssetUrl("");
		setUsername("");
		setPassword("");
		setCardNo("");
		setCardExpiry("");
		setCardCode("");
		setCardName("");
		setApiKey("");
		setApiSecret("");
		setDocs("");
		setAssetNotes("");
		setShowPassword(false);
	};

	const acceptAssetRequest = () => {
		setAcceptAsset(assetRequestInfo);
		setAssetName(assetRequestInfo.name);
		handleAssetTypeChange(assetRequestInfo.type_);
		setAssetRequestInfo(null);
		setAssetModal(true);
	};

	const formatMsisdn = (msisdn) => {
		const cleaned = ("" + msisdn).replace(/\D/g, "");
		const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
		if (match === null) {
			return msisdn;
		}
		return match[1] + " " + match[2] + " " + match[3];
	};

	const loadShareOptions = async (inputValue) => {
		const requestOptions = {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": user ? user.session_token : "",
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/groups/${id}/share-options/${assetInfo.id}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			console.log("Something went wrong.");
		} else {
			return data.group_members?.map((GroupMember) => ({
				value: GroupMember.user.id,
				label: GroupMember.user.username
					? `${GroupMember.user.username} (${GroupMember.user.email})`
					: GroupMember.user.email,
			}));
		}
	};

	const shareAsset = async (e) => {
		e.preventDefault();
		setActionLoading(true);
		const requestOptions = {
			method: "POST",
			headers: {
				"X-Auth-Token": user ? user.session_token : "",
			},
			body: JSON.stringify({
				user_ids: shareTo,
				allow_edit: shareAllowEdit,
				access_expiration: shareExpiration,
				url: assetUrl,
				notes: assetNotes,
				username: username,
				password: password,
				msisdn: assetMsisdn,
				card_no: cardNo,
				expiration: cardExpiry,
				cvv: cardCode,
				card_name: cardName,
				api_key: apiKey,
				api_secret: apiSecret,
				documentation: docs,
				street: street,
				city: city,
				province: province,
				country: country,
				postal_code: postalCode,
				contact_name: contactName,
				contact_email: contactEmail,
				contact_msisdn: contactMsisdn,
				wifi_name: wifiName,
				wifi_password: wifiPassword,
				wifi_encryption: wifiEncryption,
				wifi_is_hidden: wifiIsHidden,
				sso: {
					id: assetInfo?.sso?.id || null,
					username: assetInfo?.sso?.username || null,
					password: assetInfo?.sso?.password || null,
					msisdn: assetInfo?.sso?.msisdn || null,
					notes: assetInfo?.sso?.notes || null,
				},
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/assets/${assetInfo.id}/access/batch`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar("Something went wrong.");
		} else {
			defaultSnackbar(data.msg);
			setShareTo([]);
			setShareInput("");
			setSharedTo(data.asset.shared_to);
		}
		setActionLoading(false);
	};

	const exportAssets = async (e) => {
		e.preventDefault();
		setExportLoading(true);
		const requestOptions = {
			method: "GET",
			headers: {
				"X-Auth-Token": user ? user.session_token : "",
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/groups/${id}/assets/export?type=${exportType}`,
			requestOptions
		);

		if (!response.ok) {
			dangerSnackbar("Something went wrong.");
		} else {
			const contentType = response.headers.get("Content-Type");
			const filename = contentType.includes("application/json")
				? "smsafe_assets.json"
				: "smsafe_assets.csv";
			const blob = await response.blob();

			const link = document.createElement("a");
			link.href = URL.createObjectURL(blob);
			link.download = filename;
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);

			setExportModal(false);
			setExportType("json");
			defaultSnackbar("Assets exported.");
		}
		setExportLoading(false);
	};

	const readImportFile = (e) => {
		e.preventDefault();
		setImportLoading(true);
		if (!importFile) {
			dangerSnackbar("Please select a file.");
			return;
		}

		const reader = new FileReader();
		const fileType = importFile.type;

		reader.onload = (e) => {
			try {
				if (fileType === "application/json") {
					// Handle JSON files
					const jsonData = JSON.parse(e.target.result);

					if (jsonData.items && Array.isArray(jsonData.items)) {
						getImportSalt(jsonData.items);
					} else {
						dangerSnackbar("Invalid JSON file.");
					}
				} else if (fileType === "text/csv") {
					// Handle CSV files
					Papa.parse(e.target.result, {
						header: true,
						skipEmptyLines: true,
						complete: (results) => {
							if (results.data.length > 0) {
								getImportSalt(results.data);
							} else {
								dangerSnackbar("Invalid CSV file.");
							}
						},
						error: () => {
							dangerSnackbar("Invalid CSV file.");
						},
					});
				} else {
					dangerSnackbar("Unsupported file type.");
				}
			} catch (error) {
				dangerSnackbar("Invalid JSON file.");
			}
		};
		reader.readAsText(importFile);
	};

	const processImportItems = async (salt, items) => {
		const assetPassword = decrypt(key, profile.user.email, salt);

		const encryptField = (text) => encryptBatch(text, assetPassword, salt);

		const newImportItems = await Promise.all(
			items.map(async (item, index) => {
				const fields = getImportFields(item, provider);

				if (fields) {
					return {
						type: fields.type,
						name: fields.name,
						url: fields.url,
						username: await encryptField(fields.username),
						password: await encryptField(fields.password),
						otp: await encryptField(fields.otp),
						card_no: await encryptField(fields.card_no),
						expiration: await encryptField(fields.expiration),
						cvv: await encryptField(fields.cvv),
						card_name: await encryptField(fields.card_name),
						msisdn: await encryptField(item.msisdn),
						api_key: await encryptField(item.api_key),
						api_secret: await encryptField(item.api_secret),
						documentation: await encryptField(item.documentation),
						street: await encryptField(item.street),
						city: await encryptField(item.city),
						province: await encryptField(item.province),
						country: await encryptField(item.country),
						postal_code: await encryptField(item.postal_code),
						contact_name: await encryptField(item.contact_name),
						contact_email: await encryptField(item.contact_email),
						contact_msisdn: await encryptField(item.contact_msisdn),
						notes: await encryptField(item.notes),
					};
				}
				return null;
			})
		);

		importAssets(newImportItems);
	};

	const importAssets = async (importItems) => {
		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/groups/${id}/assets/import`,
			{
				method: "POST",
				headers: {
					"X-Auth-Token": user ? user.session_token : "",
				},
				body: JSON.stringify({
					items: importItems,
				}),
			}
		);

		if (!response.ok) {
			dangerSnackbar("Failed to upload file.");
		} else {
			defaultSnackbar("Assets imported.");
			getAssets();
			setTab("assets");
		}
		setImportLoading(false);
		setImportModal(false);
	};

	const handleFileChange = (e) => {
		setImportFile(e.target.files[0]);
	};

	const copyAssetLink = (path) => {
		navigator.clipboard.writeText(path);
		defaultSnackbar("Copied to clipboard.");
	};

	const generateAssetLink = async (e) => {
		e.preventDefault();

		setLinkLoading(true);
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": user ? user.session_token : "",
			},
			body: JSON.stringify({
				email: linkEmail,
				url: assetUrl,
				notes: assetNotes,
				username: username,
				password: password,
				msisdn: assetMsisdn,
				card_no: cardNo,
				expiration: cardExpiry,
				cvv: cardCode,
				card_name: cardName,
				api_key: apiKey,
				api_secret: apiSecret,
				documentation: docs,
				street: street,
				city: city,
				province: province,
				country: country,
				postal_code: postalCode,
				contact_name: contactName,
				contact_email: contactEmail,
				contact_msisdn: contactMsisdn,
				wifi_name: wifiName,
				wifi_password: wifiPassword,
				wifi_encryption: wifiEncryption,
				wifi_is_hidden: wifiIsHidden,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/assets/${assetInfo?.id}/links`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar(data.msg);
		} else {
			defaultSnackbar(data.msg);
			setAssetLinks(data.links);
			setLinkForm(false);
			setLinkEmail("");
		}
		setLinkLoading(false);
	};

	useMemo(() => {
		setAssetErrors([]);
	}, [assetType]);

	useEffect(() => {
		let prevNameInput = nameInput;

		if (name === prevNameInput) {
			setEditEnabled(false);
		} else {
			setEditEnabled(true);
		}
	}, [name, nameInput]);

	useEffect(() => {
		if (areaCode && phoneNumber) {
			setForwardSms(`${areaCode.value}${phoneNumber}`);
		}
	}, [areaCode, phoneNumber]);

	useEffect(() => {
		getOptions();
	}, []);

	useEffect(() => {
		getData();
	}, [id]);

	useEffect(() => {
		const timer = setTimeout(() => {
			var updatedParams = {};
			if (!search) {
				updatedParams = {};
			} else {
				updatedParams = { q: search };
			}
			if (searchParams.get("tab")) {
				updatedParams["tab"] = searchParams.get("tab");
			}
			setSearchParams(updatedParams);
		}, 500);
		return () => clearTimeout(timer);
	}, [search]);

	const filterAssets = (filterAssets) => {
		if (searchParams.get("q")) {
			setDisplayAssets(
				filterAssets.filter(
					(asset) =>
						asset.url
							?.toLowerCase()
							.includes(searchParams.get("q").toLowerCase()) ||
						asset.name
							?.toLowerCase()
							.includes(searchParams.get("q").toLowerCase()) ||
						asset.username
							?.toLowerCase()
							.includes(searchParams.get("q").toLowerCase())
				)
			);
		} else {
			setDisplayAssets(filterAssets);
		}
	};

	useEffect(() => {
		setTabLoading(true);
		switch (tab) {
			case "inbox":
				getMessages();
				break;
			case "numbers":
				getNumbers();
				break;
			case "assets":
				getAssets();
				break;
			case "members":
				getMembers();
				break;
			case "activity":
				getLogs();
				break;
		}
	}, [tab]);

	useEffect(() => {
		switch (tab) {
			case "inbox":
				getMessages();
				break;
			case "numbers":
				getNumbers();
				break;
			case "assets":
				filterAssets(assets);
				break;
			case "members":
				getMembers();
				break;
			case "activity":
				getLogs();
				break;
		}
	}, [searchParams]);

	useEffect(() => {
		if (msisdnTab === "recover") {
			getDeletedNumbers();
		}
	}, [msisdnTab]);

	const nextMessage = async () => {
		const currentSearch = new URLSearchParams(window.location.search);
		currentSearch.set("page", page + 1);
		const requestOptions = {
			method: "GET",
			headers: {
				"X-Auth-Token": user ? user.session_token : "",
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/groups/${id}/messages?${currentSearch}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			console.log("Something went wrong.");
		} else {
			setMessages((prevMessages) => [...prevMessages, ...data.messages]);
		}
		setInboxLoading(false);
	};

	useEffect(() => {
		const handleScroll = () => {
			const scrollPosition = window.innerHeight + window.scrollY + 1;
			if (
				scrollPosition >= document.body.offsetHeight &&
				!inboxLoading &&
				messages?.length < meta?.total
			) {
				setInboxLoading(true);
				nextMessage();
				setPage(page + 1);
			} else {
				setInboxLoading(false);
			}
		};

		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, [meta, messages, page]);

	const handleEmailCheck = (checked) => {
		if (checked) {
			setForwardEmailModal(true);
		} else {
			if (group.forward_email) {
				setRemoveEmailModal(true);
			}
		}
	};

	const handlePushCheck = (checked) => {
		if (checked) {
			setForwardPushModal(true);
		} else {
			if (group.forward_push) {
				setRemovePushModal(true);
			}
		}
	};

	const handleSmsCheck = (checked) => {
		if (checked) {
			setForwardSmsModal(true);
		} else {
			if (group.forward_sms) {
				setRemoveSmsModal(true);
			}
		}
	};

	const handleForwardEmailClose = () => {
		forwardEmailRef.current.checked = false;
		setForwardEmailModal(false);
		setForwardEmail("");
	};

	const handleForwardPushClose = () => {
		forwardPushRef.current.checked = false;
		setForwardPushModal(false);
		setForwardPush("");
	};

	const handleForwardSmsClose = () => {
		forwardSmsRef.current.checked = false;
		setForwardSmsModal(false);
		setForwardSms("");
		setPhoneNumber("");
		setAreaCode("");
	};

	const handleRemoveEmailClose = () => {
		forwardEmailRef.current.checked = true;
		setRemoveEmailModal(false);
	};

	const handleRemovePushClose = () => {
		forwardPushRef.current.checked = true;
		setRemovePushModal(false);
	};

	const handleRemoveSmsClose = () => {
		forwardSmsRef.current.checked = true;
		setRemoveSmsModal(false);
	};

	const copyMsisdn = (value) => {
		navigator.clipboard.writeText(value);
		defaultSnackbar("Copied to clipboard.");
		logCopy(value);
	};

	const copyAssetItem = async (asset_id, value) => {
		if (asset_id) {
			const isAllowed = await confirmAssetAccess(asset_id);
			if (!isAllowed) {
				setRequestAssetId(asset_id);
			} else {
				navigator.clipboard.writeText(value);
				defaultSnackbar("Copied to clipboard.");
			}
		} else {
			navigator.clipboard.writeText(value);
			defaultSnackbar("Copied to clipboard.");
		}
	};

	onMessageListener()
		.then((payload) => {
			getMessages();
		})
		.catch((err) => console.log("failed: ", err));

	// const handleSubscription = () => {
	// 	if (profile.payment_methods && profile.payment_methods.data.length !== 0) {
	// 		navigate("/settings");
	// 	} else {
	// 		navigate(`/plans?redirect_url=/safes/${id}`, {
	// 			state: { current_plan: profile.user.tier },
	// 		});
	// 	}
	// };

	return (
		<>
			<div className="content full-page">
				{loading ? (
					<PageLoader />
				) : group?.expires_at ? (
					<div className="lock-container flex-column">
						<i className="title-lg opacity-7 ri-lock-2-line" />
						<span className="opacity-7 mb-10">This safe is locked</span>
						<p className="opacity-5 text-sm">
							Please pay your outstanding balance to unlock this safe.
						</p>
					</div>
				) : (
					<>
						<div className="border-bottom full">
							<Container>
								<Link to="/safes" className="flex-row-left mb-30 fit">
									<i className="ri-arrow-left-line text-xl me-2" />
									<div className="group-icon bg-gray flex-column border">
										<span className="text-xl">{group?.name[0]}</span>
									</div>
									<div>
										<span className="text-xl text-medium-inter block">
											{group?.name}
										</span>
										<span className="label-md opacity-3 block">
											{members?.length}{" "}
											{members?.length === 1 ? "member" : "members"}
										</span>
									</div>
								</Link>
								{!loading && group?.group_v_msisdns?.length === 0 && (
									<div className="group-cta bg-default rounded p-30 flex-row-left mb-30">
										<Row className="full gx-0">
											<Col className="col-md-8 col-12">
												<h2 className="text-lg text-white text-medium-inter">
													Add phone number
												</h2>
												<p className="mb-0 label-md text-white opacity-7">
													You can use these phone numbers for receiving and
													sharing OTP from websites and apps.
												</p>
											</Col>
											<Col className="col-md-4 col-12 group-cta-btn-container flex-row-right">
												<Button
													variant="light"
													className="group-banner-btn btn-sm text-sm text-blue text-bold-inter"
													onClick={() => setNumberModal(true)}
												>
													Get Number
												</Button>
											</Col>
										</Row>
									</div>
								)}
								<Tabs value={tab} variant="scrollable">
									<Tab
										value="assets"
										className="settings-tab text-default text-sm"
										onClick={() => {
											setTab("assets");
											setSearch("");
										}}
										label={
											<Tooltip
												enterNextDelay={1000}
												title="Store and share login credentials, card details, 2FA codes, and more."
											>
												<span>Assets</span>
											</Tooltip>
										}
									/>
									<Tab
										value="inbox"
										className="settings-tab text-default text-sm"
										onClick={() => {
											setTab("inbox");
											setSearch("");
										}}
										label={
											<Tooltip
												enterNextDelay={1000}
												title="View messages sent to your virtual mobile number/s."
											>
												<span>Inbox</span>
											</Tooltip>
										}
									/>
									{/* <Tab
										value="numbers"
										className="settings-tab text-default text-sm"
										onClick={() => {
											setTab("numbers");
											setSearch("");
										}}
										label={
											<Tooltip
												enterNextDelay={1000}
												title="Manage virtual numbers that you can use for SMS one-time passcodes."
											>
												<span>Numbers</span>
											</Tooltip>
										}
									/> */}
									<Tab
										value="members"
										className="settings-tab text-default text-sm"
										onClick={() => {
											setTab("members");
											setSearch("");
										}}
										label={
											<Tooltip
												enterNextDelay={1000}
												title="Share your assets with teammates."
											>
												<span>Members</span>
											</Tooltip>
										}
									/>
									<Tab
										value="activity"
										className="settings-tab text-default text-sm"
										onClick={() => {
											setTab("activity");
											setSearch("");
										}}
										label={
											<Tooltip
												enterNextDelay={1000}
												title="View user activity within this safe."
											>
												<span>Activity</span>
											</Tooltip>
										}
									/>
									{isOwner && (
										<Tab
											value="settings"
											className="settings-tab text-default text-sm"
											onClick={() => {
												setTab("settings");
												setSearch("");
											}}
											label={
												<Tooltip
													enterNextDelay={1000}
													title="Edit this safe, manage message forwarding options and more."
												>
													<span>Settings</span>
												</Tooltip>
											}
										/>
									)}
								</Tabs>
							</Container>
						</div>
						<Container>
							{tab === "inbox" && (
								<div className="tab-panel">
									{group?.is_expired ? (
										<Row>
											<Col className="col-lg-8 col-12">
												<div className="p-20 bg-danger rounded-4 mb-20">
													<p className="mb-0 text-sm text-white">
														Messaging is disabled for this safe, please
														subscribe to activate this feature.
													</p>
												</div>
											</Col>
										</Row>
									) : group?.numbers_expire_at && isOwner ? (
										<Row>
											<Col className="col-lg-8 col-12">
												<div className="p-20 bg-warning rounded-4 mb-20">
													<p className="mb-0 text-sm">
														Your phone numbers will be permanently deleted on{" "}
														{format(
															new Date(group.numbers_expire_at),
															"MMM dd, yyyy"
														)}
														. Please{" "}
														<a href="/plans" className="text-bold-inter">
															upgrade your plan
														</a>{" "}
														to keep them.
													</p>
												</div>
											</Col>
										</Row>
									) : (
										""
									)}
									<Row>
										<Col className="page-content col-lg-8 col-12">
											<div className="page-content-header group-tab-header flex-row-left col-lg-6 col-12">
												<Form.Control
													className="group-tab-search rounded-4"
													placeholder="Search inbox"
													value={search}
													onChange={(e) => setSearch(e.target.value)}
												/>
											</div>
											{tabLoading
												? ""
												: lockedMessages?.map((lockedMessage) => (
														<div className="bg-white rounded-4 p-20 mb-30">
															<Row>
																<Col>
																	<span className="text-sm block">
																		<span className="text-medium-inter">
																			{lockedMessage.msisdn}
																		</span>{" "}
																		has {lockedMessage.count} unread{" "}
																		{lockedMessage.count === 1
																			? "message."
																			: "messages."}
																	</span>
																	<span className="label-md opacity-5">
																		Recover this number to view them.
																	</span>
																</Col>
																<Col className="flex-row-right">
																	<Button
																		className="btn-sm btn-default text-sm text-medium-inter"
																		onClick={() => {
																			setNumberModal(true);
																			setMsisdnTab("recover");
																		}}
																	>
																		Recover Number
																	</Button>
																</Col>
															</Row>
														</div>
												  ))}
											{tabLoading ? (
												<TabLoader />
											) : messages.length === 0 ? (
												<EmptyPage label="No messages to display" />
											) : (
												messages.map((message) => (
													<Message
														key={message.id}
														message={message}
														user={user}
													/>
												))
											)}
											{inboxLoading && <MessageLoader />}
										</Col>
										<Col className="page-sub-content col-lg-4 col-12 content-lg">
											<div className="page-content-header flex-row-left">
												<span className="text-sm opacity-7">Phone Numbers</span>
											</div>
											<div className="bg-white rounded-4 mb-30">
												{groupMsisdns?.length === 0 && orders.length === 0 ? (
													<div>
														<div className="p-30 align-center">
															<span className="text-sm opacity-5">
																No numbers yet
															</span>
														</div>
													</div>
												) : (
													groupMsisdns?.map((groupMsisdn) => (
														<div
															key={groupMsisdn.id}
															className="safe-msisdn-item p-20 flex-row-left"
														>
															<div
																className="msisdn-country bg-cover"
																style={{
																	background: `url(${groupMsisdn.v_msisdn.flag_uri})`,
																}}
															/>
															<span className="text-lg text-medium-inter">
																{groupMsisdn.v_msisdn.msisdn}
															</span>
															<div className="inbox-msisdn-control flex-row-right">
																<Button
																	className="flex-column"
																	onClick={() =>
																		copyMsisdn(groupMsisdn.v_msisdn.msisdn)
																	}
																>
																	<i className="ri-file-copy-line text-sm" />
																</Button>
																<Dropdown className="dropdown-default">
																	<Dropdown.Toggle className="flex-column no-caret">
																		<i className="ri-settings-line text-sm" />
																	</Dropdown.Toggle>
																	<Dropdown.Menu>
																		<Dropdown.Item
																			href="#"
																			onClick={(e) => {
																				e.preventDefault();
																				setToDeleteMsisdn(
																					groupMsisdn.v_msisdn.id
																				);
																			}}
																			className="text-sm text-danger"
																		>
																			Delete
																		</Dropdown.Item>
																	</Dropdown.Menu>
																</Dropdown>
															</div>
														</div>
													))
												)}
												{orders.length !== 0 && (
													<div className="order-container p-20">
														{orders.map((order) => (
															<Order
																key={order.id}
																item={order}
																orders={orders}
																setOrders={setOrders}
																refresh={getData}
																user={user}
															/>
														))}
													</div>
												)}
												{isOwner && (
													<div className="p-20 border-top">
														<Button
															className="btn-default btn-sm full text-sm"
															onClick={() => setNumberModal(true)}
														>
															Add Number
														</Button>
													</div>
												)}
											</div>
										</Col>
									</Row>
								</div>
							)}
							{tab === "numbers" && (
								<div className="tab-panel">
									<Row>
										<Col className="page-content col-lg-8 col-12">
											<div className="page-content-header group-tab-header flex-row-left">
												<div className="col-lg-6 col-12">
													<Form.Control
														className="group-tab-search rounded-4"
														placeholder="Search mobile numbers"
														value={search}
														onChange={(e) => setSearch(e.target.value)}
													/>
												</div>
												<div className="col-lg-6 col-12 flex-row-right content-lg">
													{isOwner && (
														<Button
															className="btn-sm btn-default text-sm flex-row-left rounded-4"
															onClick={() => setNumberModal(true)}
														>
															<i className="ri-add-line" />
															Add Number
														</Button>
													)}
												</div>
											</div>
											{tabLoading ? (
												<TabLoader />
											) : (
												<div>
													{groupMsisdns.length === 0 && orders.length === 0 ? (
														<EmptyPage label="No numbers to display" />
													) : (
														groupMsisdns.map((groupMsisdn) => (
															<div
																key={groupMsisdn.id}
																className="dashboard-item p-20 rounded-4 bg-white mb-10 flex-row-left"
															>
																<div
																	className="msisdn-country bg-cover"
																	style={{
																		background: `url(${groupMsisdn.v_msisdn.flag_uri})`,
																	}}
																/>
																<span
																	className="text-lg text-medium-inter"
																	onCopy={() =>
																		logCopy(groupMsisdn.v_msisdn.msisdn)
																	}
																>
																	{formatMsisdn(groupMsisdn.v_msisdn.msisdn)}
																</span>
																<div className="inbox-msisdn-control flex-row-right">
																	<Button
																		className="flex-column"
																		onClick={() =>
																			copyMsisdn(groupMsisdn.v_msisdn.msisdn)
																		}
																	>
																		<i className="ri-file-copy-line text-sm" />
																	</Button>
																	{isOwner && (
																		<Dropdown className="dropdown-default">
																			<Dropdown.Toggle className="flex-column no-caret">
																				<i className="ri-settings-line text-sm" />
																			</Dropdown.Toggle>
																			<Dropdown.Menu>
																				<Dropdown.Item
																					href="#"
																					onClick={(e) => {
																						e.preventDefault();
																						setToDeleteMsisdn(
																							groupMsisdn.v_msisdn.id
																						);
																					}}
																					className="text-sm text-danger"
																				>
																					Delete
																				</Dropdown.Item>
																			</Dropdown.Menu>
																		</Dropdown>
																	)}
																</div>
															</div>
														))
													)}
													{orders.length !== 0 && (
														<div className="order-container">
															{orders.map((order) => (
																<Order
																	key={order.id}
																	item={order}
																	orders={orders}
																	setOrders={setOrders}
																	refresh={getNumbers}
																	user={user}
																/>
															))}
														</div>
													)}
												</div>
											)}
										</Col>
										<Col className="page-sub-content col-lg-4 col-12">
											{isOwner && <div className="bg-white rounded-4"></div>}
										</Col>
									</Row>
								</div>
							)}
							{/* {tab === "2fa" && (
								<div className="tab-panel">
									<Row>
										<Col className="page-content col-lg-8 col-12">
											<div className="page-content-header group-tab-header flex-row-left">
												<div className="col-lg-6 col-12">
													<Form.Control
														className="group-tab-search rounded-4"
														placeholder="Search 2FAs"
														value={search}
														onChange={(e) => setSearch(e.target.value)}
													/>
												</div>
												<div className="col-lg-6 col-12 flex-row-right content-lg">
													{isOwner && (
														<Button
															className="btn-sm btn-default text-sm flex-row-left rounded-4"
															onClick={() => setMfaModal(true)}
														>
															<i className="ri-add-line" />
															Add 2FA
														</Button>
													)}
												</div>
											</div>
											{groupMfas?.length === 0 ? (
												<EmptyPage label="No 2FA codes to display" />
											) : (
												groupMfas.map((groupMfa) => (
													<MfaToken
														key={groupMfa.id}
														mfa={groupMfa.mfa_token}
														setToDelete={setToDelete}
													/>
												))
											)}
											<Button
												className="floating-add-btn content-sm btn-default"
												onClick={() => setMfaModal(true)}
											>
												<i className="ri-add-line text-white text-xl" />
											</Button>
										</Col>
									</Row>
								</div>
							)} */}
							{tab === "assets" && (
								<div className="tab-panel">
									<Row>
										<Col className="page-content col-lg-8 col-12">
											<div className="page-content-header group-tab-header flex-row-left">
												<div className="col-lg-6 col-12">
													<Form.Control
														className="group-tab-search rounded-4"
														placeholder="Search assets"
														value={search}
														onChange={(e) => setSearch(e.target.value)}
													/>
												</div>
												<div className="col-lg-6 col-12 flex-row-right content-lg">
													{isOwner ? (
														<Button
															className="btn-sm btn-default text-sm flex-row-left rounded-4"
															onClick={() => setAssetModal(true)}
														>
															<i className="ri-add-line" />
															Add Asset
														</Button>
													) : (
														<Button
															className="btn-sm btn-default text-sm flex-row-left rounded-4"
															onClick={() => setAssetRequestModal(true)}
														>
															<i className="ri-add-line" />
															Request Asset
														</Button>
													)}
												</div>
											</div>
											{tabLoading
												? ""
												: assetRequests?.map((assetRequest) => (
														<div
															key={assetRequest.id}
															className="asset-request bg-white rounded-4 mb-10 flex-row-left"
														>
															<div
																className="flex-row-left full p-20 pointer"
																onClick={() =>
																	setAssetRequestInfo(assetRequest)
																}
															>
																<div>
																	<span className="text-medium-inter block">
																		{assetRequest.name}
																	</span>
																	<span className="label-md opacity-7 block">
																		{assetRequest.type_} asset requested by{" "}
																		{assetRequest.user.username}
																	</span>
																</div>
															</div>
															<div className="asset-control flex-row-right full-height">
																<div className="asset-request-status rounded-4 flex-column">
																	<span className="label-md text-white">
																		{assetRequest.status}
																	</span>
																</div>
															</div>
														</div>
												  ))}
											{tabLoading ? (
												<TabLoader />
											) : displayAssets?.length === 0 ? (
												<EmptyPage label="No assets to display" />
											) : (
												displayAssets
													.sort((a, b) => a.name.localeCompare(b.name))
													.map((asset) => (
														<div
															key={asset.id}
															className="bg-white rounded-4 mb-10"
														>
															<div
																className="asset-body flex-row-left full p-20 pointer"
																onClick={() => handleAsset(asset)}
															>
																<div
																	className="asset-logo border flex-column"
																	style={{
																		background: asset?.logo
																			? `url(${asset.logo})`
																			: "#3c57cc",
																	}}
																>
																	{!asset?.logo && (
																		<span className="text-medium-inter text-white text-sm upper">
																			{asset.name[0]}
																		</span>
																	)}
																</div>
																<div>
																	<span className="text-medium-inter block">
																		{asset.name}
																	</span>
																	{asset.type_ === "Login" &&
																		(asset.login_type === "username" ? (
																			<span className="text-sm opacity-7 block">
																				{asset.username}
																			</span>
																		) : (
																			<span className="text-sm opacity-7 block">
																				SSO: {asset.sso?.name}{" "}
																				{asset.sso?.username
																					? `(${asset.sso?.username})`
																					: ""}
																			</span>
																		))}
																	{asset.type_ === "Card" && (
																		<span className="text-sm opacity-7 block">
																			*{asset.card_no.slice(-4)}
																		</span>
																	)}
																	{asset.type_ === "Wifi" && (
																		<span className="text-sm opacity-7 block">
																			{asset.wifi_name}
																		</span>
																	)}
																</div>
															</div>
															<div className="asset-footer">
																<div className="flex-row-left border-top">
																	<div className="col-6 flex-row-left">
																		<span className="asset-type text-sm text-medium-inter">
																			{asset.type_}
																		</span>
																		{asset.url && (
																			<span className="text-sm opacity-7 ellipsis">
																				{asset.url}
																			</span>
																		)}
																	</div>
																	<div className="col-6 flex-row-right">
																		<i className="ri-time-line opacity-7 me-1" />
																		<span className="label-md opacity-7">
																			{getAge(asset.updated_at)}
																		</span>
																	</div>
																</div>
															</div>
															{/* <div className="asset-control flex-row-right full-height">
															{asset.type_ === "Login" && (
																<>
																	<Button
																		className="flex-column content-lg"
																		onClick={() =>
																			copyAssetItem(asset.id, asset.username)
																		}
																	>
																		<i className="ri-user-line text-sm" />
																	</Button>
																	<Button
																		className="flex-column content-lg"
																		onClick={() =>
																			copyAssetItem(asset.id, asset.password)
																		}
																	>
																		<i className="ri-lock-2-line text-sm" />
																	</Button>
																</>
															)}
															{asset.type_ === "Card" && (
																<>
																	<Button
																		className="flex-column content-lg"
																		onClick={() =>
																			copyAssetItem(asset.id, asset.card_no)
																		}
																	>
																		<i className="ri-hashtag text-sm" />
																	</Button>
																	<Button
																		className="flex-column content-lg"
																		onClick={() =>
																			copyAssetItem(asset.id, asset.expiration)
																		}
																	>
																		<i className="ri-calendar-line text-sm" />
																	</Button>
																	<Button
																		className="flex-column content-lg"
																		onClick={() =>
																			copyAssetItem(asset.id, asset.cvv)
																		}
																	>
																		<i className="ri-lock-2-line text-sm" />
																	</Button>
																</>
															)}
															{assetType === "API" && (
																<>
																	<Button
																		className="flex-column content-lg"
																		onClick={() =>
																			copyAssetItem(asset.id, asset.api_key)
																		}
																	>
																		<i className="ri-key-line text-sm" />
																	</Button>
																	<Button
																		className="flex-column content-lg"
																		onClick={() =>
																			copyAssetItem(asset.id, asset.api_secret)
																		}
																	>
																		<i className="ri-lock-2-line text-sm" />
																	</Button>
																</>
															)}
														</div> */}
														</div>
													))
											)}
											<Button
												className="floating-add-btn content-sm btn-default"
												onClick={() => setAssetModal(true)}
											>
												<i className="ri-add-line text-white text-xl" />
											</Button>
										</Col>
									</Row>
								</div>
							)}
							{tab === "members" && (
								<div className="tab-panel">
									<Row>
										<Col className="page-content col-lg-8 col-12">
											{group?.is_expired ? (
												<div className="p-20 bg-danger rounded-4 mb-20">
													<p className="mb-0 text-sm text-white">
														Sharing is disabled for this safe, please subscribe
														to activate this feature.
													</p>
												</div>
											) : group?.expires_at ? (
												<div className="p-20 bg-warning rounded-4 mb-20">
													<p className="mb-0 text-sm">
														Sharing will soon be disabled for this safe, please
														subscribe to continue using this feature.
													</p>
												</div>
											) : (
												""
											)}
											<div className="page-content-header group-tab-header flex-row-left">
												<div className="col-lg-6 col-12">
													<Form.Control
														className="group-tab-search rounded-4"
														placeholder="Search members"
														value={search}
														onChange={(e) => setSearch(e.target.value)}
													/>
												</div>
												<div className="col-lg-6 col-12 flex-row-right">
													{isOwner && (
														<Button
															className="btn-default content-lg btn-sm flex-row-center rounded-4 text-sm"
															onClick={() => setMemberModal(true)}
														>
															<i className="ri-user-add-line" />
															Invite Member
														</Button>
													)}
												</div>
											</div>
											<div className="page-content-body">
												{tabLoading ? (
													<TabLoader />
												) : (
													members?.map((member) => (
														<div
															key={member.id}
															className="group-member-item content-item p-20 mb-10 bg-white rounded-4 flex-row-left"
														>
															<Row key={member.id} className="full">
																<Col className="col-lg-8 col-12 flex-row-left">
																	<div
																		className="group-member-icon flex-column"
																		style={{
																			background: member?.user.photo
																				? `url(${member.user.photo})`
																				: "#3c57cc",
																		}}
																	>
																		{!member?.user.photo && (
																			<span className="text-medium-inter text-white text-sm upper">
																				{member.user.username
																					? member.user.username[0]
																					: member.user.email[0]}
																			</span>
																		)}
																	</div>
																	{member?.user.username ? (
																		<div>
																			<span className="text-sm">
																				{member?.user.username}
																			</span>
																			<span className="label-md opacity-5 block">
																				{member?.user.email}
																			</span>
																		</div>
																	) : (
																		<span className="text-sm opacity-5">
																			{member?.user.email}
																		</span>
																	)}
																</Col>
																<Col className="col-lg-4 col-12 member-action flex-row-right">
																	{isOwner &&
																		(member?.user.username === user.username ? (
																			// <span className="text-sm opacity-5 italic">Me</span>
																			""
																		) : member?.user.email_verified ? (
																			<Button
																				className="group-member-btn btn-gray text-sm text-medium-inter flex-column text-red"
																				onClick={() =>
																					setToRemove(member?.user)
																				}
																			>
																				Remove
																			</Button>
																		) : (
																			<div className="flex-row-left">
																				<span className="member-invite-label label-md text-medium-inter rounded-4">
																					Invite Sent
																				</span>
																				<Button
																					className="group-member-btn cancel-invite-btn btn-gray text-sm text-medium-inter flex-column text-default"
																					onClick={() =>
																						setToRemove(member?.user)
																					}
																				>
																					Cancel
																				</Button>
																			</div>
																		))}
																</Col>
															</Row>
														</div>
													))
												)}
												<Button
													className="floating-add-btn content-sm btn-default"
													onClick={() => setMemberModal(true)}
												>
													<i className="ri-user-add-line text-white text-xl" />
												</Button>
											</div>
										</Col>
									</Row>
								</div>
							)}
							{tab === "activity" && (
								<div className="tab-panel">
									<Row>
										<Col className="page-content col-lg-8 col-12">
											<div className="page-content-header group-tab-header flex-row-left">
												<div className="col-lg-6 col-12">
													<Form.Control
														className="group-tab-search rounded-4"
														placeholder="Search activity"
														value={search}
														onChange={(e) => setSearch(e.target.value)}
													/>
												</div>
											</div>
											<div className="page-content-body">
												{tabLoading ? (
													<TabLoader />
												) : (
													logs?.map((log) => (
														<div
															key={log.id}
															className="activity-container mb-10 bg-white rounded-4"
														>
															<div
																className="activity-item flex-row-left p-20 pointer"
																onClick={() =>
																	setActiveLog(
																		activeLog === log.id ? null : log.id
																	)
																}
															>
																<div>
																	<p className="activity-log-content text-sm mb-2">
																		{log.session.user.username}{" "}
																		{log.description.toLowerCase()}
																	</p>
																	<div className="flex-row-left">
																		<span className="label-md opacity-7">
																			{format(
																				new Date(`${log.created_at}`),
																				"MMM dd, yyyy - hh:mm a"
																			)}
																		</span>
																	</div>
																</div>
																{log.device_flag || log.loc_flag ? (
																	<div className="group-item-lock activity-warning">
																		<i className="ri-error-warning-fill text-warning" />
																		<span className="tooltip">
																			Expand to see warnings.
																		</span>
																	</div>
																) : (
																	""
																)}
																<i
																	className={`activity-dropdown ri-arrow-down-s-line text-xl ${
																		activeLog === log.id && "active"
																	}`}
																/>
															</div>
															<div
																className={`activity-body p-20 ${
																	activeLog === log.id && "active"
																}`}
															>
																<div className="rounded border p-20">
																	{log.device_flag && (
																		<div className="activity-warning-container rounded-4 flex-row-left mb-15 ">
																			<i className="ri-information-line text-lg me-1" />
																			<p className="text-sm mb-0">
																				{log.device_flag}
																			</p>
																		</div>
																	)}
																	{log.loc_flag && (
																		<div className="activity-warning-container rounded-4 flex-row-left mb-15 ">
																			<i className="ri-information-line text-lg me-1" />
																			<p className="text-sm mb-0">
																				{log.loc_flag}
																			</p>
																		</div>
																	)}
																	<div className="activity-info mb-1">
																		<span className="text-medium-inter text-sm">
																			Host:{" "}
																		</span>
																		<span className="text-light-inter text-sm">
																			{log.origin_host}
																		</span>
																	</div>
																	<div className="activity-info mb-1">
																		<span className="text-medium-inter text-sm">
																			Browser:{" "}
																		</span>
																		<span className="text-light-inter text-sm">
																			{log.session.browser}
																		</span>
																	</div>
																	<div className="activity-info mb-1">
																		<span className="text-medium-inter text-sm">
																			Operating System:{" "}
																		</span>
																		<span className="text-light-inter text-sm">
																			{log.session.operating_system}
																		</span>
																	</div>
																	<div className="activity-info mb-1">
																		<span className="text-medium-inter text-sm">
																			Device:{" "}
																		</span>
																		<span className="text-light-inter text-sm">
																			{log.session.device_brand}{" "}
																			{log.session.device_model}
																		</span>
																	</div>
																	<div className="activity-info">
																		<span className="text-medium-inter text-sm">
																			Location:{" "}
																		</span>
																		<span className="text-light-inter text-sm">
																			{log.origin_city &&
																				log.origin_city + ", "}
																			{log.origin_country}
																		</span>
																	</div>
																</div>
															</div>
														</div>
													))
												)}
											</div>
										</Col>
									</Row>
								</div>
							)}
							{tab === "settings" && (
								<div className="tab-panel">
									<Row>
										<Col className="col-lg-8 col-12">
											<div className="bg-white rounded-4">
												<Form onSubmit={editGroup} className="settings-group">
													<div className="p-30 border-bottom">
														<Row className="form-block-sm">
															<Col>
																<Form.Label>Safe Name</Form.Label>
																<Form.Control
																	className="form-input"
																	placeholder="Enter group name"
																	value={nameInput}
																	onChange={(e) => setNameInput(e.target.value)}
																/>
															</Col>
														</Row>
														<Row>
															<Col>
																<Button
																	type="submit"
																	className="group-settings-btn btn-default text-sm btn-sm rounded-4"
																	disabled={!editEnabled || actionLoading}
																>
																	{actionLoading ? "Saving.." : "Save Changes"}
																</Button>
															</Col>
														</Row>
													</div>
													<div className="p-30 border-bottom">
														<div className="mb-15">
															<Form.Label>Forwarding</Form.Label>
															<p className="mb-0 text-sm opacity-5">
																Select where you want to forward incoming
																messages for this safe.
															</p>
														</div>
														<div>
															<div className="forward-option flex-row-left">
																{group && (
																	<input
																		ref={forwardEmailRef}
																		type="checkbox"
																		defaultChecked={
																			group.forward_email ? true : false
																		}
																		onChange={(e) =>
																			handleEmailCheck(e.target.checked)
																		}
																		className="cbx hidden"
																		id="forwardEmail"
																	/>
																)}
																<label
																	htmlFor="forwardEmail"
																	className="lbl"
																></label>
																<div className="forward-option-value">
																	<span className="text-sm block">Email</span>
																	<span className="label-md opacity-5 block ellipsis">
																		{group?.forward_email}
																	</span>
																</div>
															</div>
															<div className="forward-option flex-row-left">
																{group && (
																	<input
																		ref={forwardPushRef}
																		type="checkbox"
																		defaultChecked={
																			group.forward_push ? true : false
																		}
																		onChange={(e) =>
																			handlePushCheck(e.target.checked)
																		}
																		className="cbx hidden"
																		id="forwardPush"
																	/>
																)}
																<label
																	htmlFor="forwardPush"
																	className="lbl"
																></label>
																<div className="forward-option-value">
																	<span className="text-sm block">Push</span>
																	<span className="label-md opacity-5 block ellipsis">
																		{group?.forward_push}
																	</span>
																</div>
															</div>
															<div className="forward-option flex-row-left">
																{group && (
																	<input
																		ref={forwardSmsRef}
																		type="checkbox"
																		defaultChecked={
																			group.forward_sms ? true : false
																		}
																		onChange={(e) =>
																			handleSmsCheck(e.target.checked)
																		}
																		className="cbx hidden"
																		id="forwardSms"
																	/>
																)}
																<label
																	htmlFor="forwardSms"
																	className="lbl"
																></label>
																<div className="forward-option-value">
																	<span className="text-sm block">SMS</span>
																	<span className="label-md opacity-5 block ellipsis">
																		{group?.forward_sms}
																	</span>
																</div>
															</div>
														</div>
													</div>
													<div className="p-30 border-bottom">
														<Row>
															<Col>
																<Form.Label className="mb-10">
																	Assets Management
																</Form.Label>
																<div className="flex-row-left">
																	<Button
																		className="btn-gray text-blue text-sm btn-sm rounded-4 flex-row-center me-3"
																		onClick={() => setImportModal(true)}
																	>
																		<i className="ri-upload-2-line" />
																		Import Assets
																	</Button>
																	<Button
																		className="btn-gray text-blue text-sm btn-sm rounded-4 flex-row-center"
																		onClick={() => setExportModal(true)}
																	>
																		<i className="ri-download-2-line" />
																		Export Assets
																	</Button>
																</div>
															</Col>
														</Row>
													</div>
													<div className="p-30">
														<Row>
															<Col>
																<Form.Label className="mb-10">
																	Danger Zone
																</Form.Label>
																<Button
																	className="group-settings-btn btn-gray text-danger text-sm block btn-sm rounded-4"
																	onClick={() => setDeleteModal(true)}
																>
																	Delete Safe
																</Button>
															</Col>
														</Row>
													</div>
												</Form>
											</div>
										</Col>
									</Row>
								</div>
							)}
						</Container>
					</>
				)}
			</div>
			<Modal
				show={memberModal}
				className="post-modal"
				onHide={() => {
					setMemberModal(false);
					setMemberEmail("");
				}}
				centered
			>
				<Modal.Header>
					<div>
						<span className="text-medium-inter text-lg">Invite to Safe</span>
						<p className="m-0 text-sm opacity-5">
							Invite a user via email to your group.
						</p>
					</div>
				</Modal.Header>
				<Form onSubmit={inviteMember}>
					<Modal.Body>
						<Row className="form-block-sm">
							<Col>
								<Form.Label>Email</Form.Label>
								<Form.Control
									type="email"
									className="form-input input-lg text-sm rounded-4"
									placeholder="Enter member email"
									value={memberEmail}
									onChange={(e) => setMemberEmail(e.target.value)}
								/>
								{profile?.user.tier === "Premium" &&
									members?.length >= profile?.user.max_members && (
										<div className="charge-warning p-20 bg-warning rounded-4">
											<div className="flex-row-left">
												<i className="ri-information-line text-lg me-1" />
												<span className="text-medium-inter label-md">
													Maximum safe members reached.
												</span>
											</div>
											<p className="mb-0 label-md">
												Additional members will be charged $2/month each.
											</p>
										</div>
									)}
							</Col>
						</Row>
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="light"
							className="modal-btn btn-md dismiss-btn text-medium-inter text-sm"
							onClick={() => {
								setMemberModal(false);
								setMemberEmail("");
							}}
						>
							Cancel
						</Button>
						<Button
							type="submit"
							className="modal-btn btn-md btn-default text-medium-inter text-sm"
							disabled={actionLoading}
						>
							{actionLoading ? "Inviting.." : "Send Invite"}
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
			<Modal show={deleteModal} onHide={() => setDeleteModal(false)} centered>
				<Modal.Body>
					<h2 className="text-lg text-medium-inter">Delete Safe</h2>
					<p className="text-sm opacity-5 mb-0">
						Are you sure you want to delete this safe?
					</p>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="light"
						className="modal-btn btn-sm dismiss-btn text-medium-inter text-sm"
						onClick={() => setDeleteModal(false)}
					>
						Cancel
					</Button>
					<Button
						variant="danger"
						className="modal-btn btn-sm text-medium-inter text-sm"
						disabled={actionLoading}
						onClick={deleteGroup}
					>
						{actionLoading ? "Deleting.." : "Delete"}
					</Button>
				</Modal.Footer>
			</Modal>
			<Modal
				show={deleteAssetId}
				onHide={() => setDeleteAssetId(null)}
				centered
			>
				<Modal.Body>
					<h2 className="text-lg text-medium-inter">Delete Asset</h2>
					<p className="text-sm opacity-5 mb-0">
						Are you sure you want to delete this asset?
					</p>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="light"
						className="modal-btn btn-sm dismiss-btn text-medium-inter text-sm"
						onClick={() => setDeleteAssetId(null)}
					>
						Cancel
					</Button>
					<Button
						variant="danger"
						className="modal-btn btn-sm text-medium-inter text-sm"
						disabled={actionLoading}
						onClick={deleteAsset}
					>
						{actionLoading ? "Deleting.." : "Delete"}
					</Button>
				</Modal.Footer>
			</Modal>
			<Modal
				show={requestAssetId}
				onHide={() => setRequestAssetId(null)}
				centered
			>
				<Modal.Body>
					<h2 className="text-lg text-medium-inter">Request Access</h2>
					<p className="text-sm opacity-5 mb-0">
						You don't have permission to view this asset. Would you like to
						request access from the owner?
					</p>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="light"
						className="modal-btn btn-sm dismiss-btn text-medium-inter text-sm"
						onClick={() => setRequestAssetId(null)}
					>
						Cancel
					</Button>
					<Button
						className="modal-btn btn-sm btn-default text-medium-inter text-sm"
						disabled={actionLoading}
						onClick={requestAccess}
					>
						{actionLoading ? "Requesting.." : "Request Access"}
					</Button>
				</Modal.Footer>
			</Modal>
			<Modal show={leaveModal} onHide={() => setLeaveModal(false)} centered>
				<Modal.Body>
					<h2 className="text-lg text-medium-inter">Leave Group</h2>
					<p className="text-sm opacity-5 mb-0">
						Are you sure you want to leave this group?
					</p>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="light"
						className="modal-btn btn-sm dismiss-btn text-medium-inter text-sm"
						onClick={() => setLeaveModal(false)}
					>
						Cancel
					</Button>
					<Button
						className="modal-btn btn-sm btn-default text-medium-inter text-sm"
						disabled={actionLoading}
						onClick={leaveGroup}
					>
						{actionLoading ? "Leaving.." : "Leave"}
					</Button>
				</Modal.Footer>
			</Modal>
			<Modal show={toRemove} onHide={() => setToRemove(null)} centered>
				<Modal.Body>
					<h2 className="text-lg text-medium-inter">Remove from Group</h2>
					<p className="text-sm opacity-5 mb-0">
						Are you sure you want to remove {toRemove?.email} from {group?.name}
						?
					</p>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="light"
						className="modal-btn btn-sm dismiss-btn text-medium-inter text-sm"
						onClick={() => setToRemove(null)}
					>
						Cancel
					</Button>
					<Button
						className="modal-btn btn-sm btn-default text-medium-inter text-sm"
						disabled={actionLoading}
						onClick={removeMember}
					>
						{actionLoading ? "Removing.." : "Remove"}
					</Button>
				</Modal.Footer>
			</Modal>
			<Modal
				show={forwardEmailModal}
				className="post-modal"
				onHide={() => handleForwardEmailClose()}
				centered
			>
				<Modal.Header>
					<div>
						<span className="text-medium-inter text-lg">
							Enable Email Forwarding
						</span>
						<p className="m-0 text-sm opacity-5">
							Forward incoming SMS from this number to your email.
						</p>
					</div>
				</Modal.Header>
				<Form onSubmit={(e) => saveForwardChannel(e, "email", forwardEmail)}>
					<Modal.Body>
						<Form.Label>Email</Form.Label>
						<Form.Control
							type="email"
							placeholder="Enter email"
							className="form-input"
							value={forwardEmail}
							onChange={(e) => setForwardEmail(e.target.value)}
						/>
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="light"
							className="modal-btn btn-md dismiss-btn text-medium-inter text-sm"
							onClick={() => handleForwardEmailClose()}
						>
							Cancel
						</Button>
						<Button
							type="submit"
							className="modal-btn btn-md btn-default text-medium-inter text-sm"
							disabled={actionLoading}
						>
							{actionLoading ? "Enabling.." : "Enable"}
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
			<Modal
				show={forwardPushModal}
				className="post-modal"
				onHide={() => handleForwardPushClose()}
				centered
			>
				<Modal.Header>
					<div>
						<span className="text-medium-inter text-lg">
							Enable Push Forwarding
						</span>
						<p className="m-0 text-sm opacity-5">
							Forward incoming SMS from this number to your webhook.
						</p>
					</div>
				</Modal.Header>
				<Form onSubmit={(e) => saveForwardChannel(e, "push", forwardPush)}>
					<Modal.Body>
						<Form.Label>Webhook URL</Form.Label>
						<Form.Control
							placeholder="Enter webhook URL"
							className="form-input"
							value={forwardPush}
							onChange={(e) => setForwardPush(e.target.value)}
						/>
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="light"
							className="modal-btn btn-md dismiss-btn text-medium-inter text-sm"
							onClick={() => handleForwardPushClose()}
						>
							Cancel
						</Button>
						<Button
							type="submit"
							className="modal-btn btn-md btn-default text-medium-inter text-sm"
							disabled={actionLoading}
						>
							{actionLoading ? "Enabling.." : "Enable"}
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
			<Modal
				show={forwardSmsModal}
				className="post-modal"
				onHide={() => handleForwardSmsClose()}
				centered
			>
				<Modal.Header>
					<div>
						<span className="text-medium-inter text-lg">
							Enable SMS Forwarding
						</span>
						<p className="m-0 text-sm opacity-5">
							Forward incoming SMS from this number to your phone.
						</p>
					</div>
				</Modal.Header>
				<Form onSubmit={(e) => saveForwardChannel(e, "sms", forwardSms)}>
					<Modal.Body>
						<Row>
							<Form.Label>Phone number</Form.Label>
							<Col className="col-4">
								<Select
									required
									defaultValue={areaCode}
									onChange={setAreaCode}
									options={smsOptions}
									placeholder="Area Code"
									components={{ Option: IconOption }}
								/>
							</Col>
							<Col className="col-8">
								<Form.Control
									required
									type="number"
									className="form-input"
									value={phoneNumber}
									onChange={(e) => setPhoneNumber(e.target.value)}
								/>
							</Col>
						</Row>
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="light"
							className="modal-btn btn-md dismiss-btn text-medium-inter text-sm"
							onClick={() => handleForwardSmsClose()}
						>
							Cancel
						</Button>
						<Button
							type="submit"
							className="modal-btn btn-md btn-default text-medium-inter text-sm"
							disabled={actionLoading}
						>
							{actionLoading ? "Enabling.." : "Enable"}
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
			<Modal
				show={removeEmailModal}
				onHide={() => handleRemoveEmailClose()}
				centered
			>
				<Modal.Body>
					<h2 className="text-lg text-medium-inter">
						Disable Email Forwarding
					</h2>
					<p className="text-sm opacity-5 mb-0">
						Are you sure you want to disable email forwarding for this phone
						number?
					</p>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="light"
						className="modal-btn btn-sm dismiss-btn text-medium-inter text-sm"
						onClick={() => handleRemoveEmailClose()}
					>
						Cancel
					</Button>
					<Button
						className="modal-btn btn-sm btn-default text-medium-inter text-sm"
						disabled={actionLoading}
						onClick={(e) => removeForwardChannel(e, "email")}
					>
						{actionLoading ? "Disabling.." : "Disable"}
					</Button>
				</Modal.Footer>
			</Modal>
			<Modal
				show={removePushModal}
				onHide={() => handleRemovePushClose()}
				centered
			>
				<Modal.Body>
					<h2 className="text-lg text-medium-inter">Disable Push Forwarding</h2>
					<p className="text-sm opacity-5 mb-0">
						Are you sure you want to disable push forwarding for this phone
						number?
					</p>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="light"
						className="modal-btn btn-sm dismiss-btn text-medium-inter text-sm"
						onClick={() => handleRemovePushClose()}
					>
						Cancel
					</Button>
					<Button
						className="modal-btn btn-sm btn-default text-medium-inter text-sm"
						disabled={actionLoading}
						onClick={(e) => removeForwardChannel(e, "push")}
					>
						{actionLoading ? "Disabling.." : "Disable"}
					</Button>
				</Modal.Footer>
			</Modal>
			<Modal
				show={removeSmsModal}
				onHide={() => handleRemoveSmsClose()}
				centered
			>
				<Modal.Body>
					<h2 className="text-lg text-medium-inter">Disable SMS Forwarding</h2>
					<p className="text-sm opacity-5 mb-0">
						Are you sure you want to disable SMS forwarding for this phone
						number?
					</p>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="light"
						className="modal-btn btn-sm dismiss-btn text-medium-inter text-sm"
						onClick={() => handleRemoveSmsClose()}
					>
						Cancel
					</Button>
					<Button
						className="modal-btn btn-sm btn-default text-medium-inter text-sm"
						disabled={actionLoading}
						onClick={(e) => removeForwardChannel(e, "sms")}
					>
						{actionLoading ? "Disabling.." : "Disable"}
					</Button>
				</Modal.Footer>
			</Modal>
			<Modal
				show={numberModal}
				className="msisdn-modal"
				onHide={() => {
					setNumberModal(false);
					setCountryCode("");
					setMsisdnTab("new");
				}}
				centered
			>
				<Modal.Header className="text-medium-inter text-lg">
					Add Number
				</Modal.Header>
				<Form onSubmit={addNumber}>
					<Modal.Body>
						<Tabs value={msisdnTab} className="mb-20">
							<Tab
								value="new"
								className="settings-tab text-default text-sm"
								onClick={() => {
									setMsisdnTab("new");
									setSearch("");
								}}
								label="New Number"
							/>
							<Tab
								value="recover"
								className="settings-tab text-default text-sm"
								onClick={() => {
									setMsisdnTab("recover");
									setSearch("");
								}}
								label="Recover Deleted Number"
							/>
						</Tabs>
						{msisdnTab === "new" && (
							<Row className="mb-30">
								<Col>
									<Form.Label>Country Code</Form.Label>
									<Select
										defaultValue={countryCode}
										onChange={setCountryCode}
										options={countryOptions}
										components={{ Option: IconOption }}
									/>
								</Col>
							</Row>
						)}
						{msisdnTab === "recover" &&
							(msisdnLoading ? (
								<TabLoader />
							) : deletedMsisdns.length === 0 && orders.length === 0 ? (
								<EmptyPage label="No numbers to display" />
							) : (
								deletedMsisdns.map((groupMsisdn) => (
									<DeletedNumber
										groupMsisdn={groupMsisdn}
										user={user}
										groupID={id}
										refresh={tab === "inbox" ? getMessages : getNumbers}
										setNumberModal={setNumberModal}
										setCountryCode={setCountryCode}
										setMsisdnTab={setMsisdnTab}
										setDeletedMsisdns={setDeletedMsisdns}
									/>
								))
							))}
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="light"
							className="modal-btn btn-md dismiss-btn text-medium-inter text-sm"
							onClick={() => {
								setNumberModal(false);
								setCountryCode("");
								setMsisdnTab("new");
							}}
						>
							Cancel
						</Button>
						{msisdnTab === "new" && (
							<Button
								type="submit"
								className="modal-btn btn-md btn-default text-medium-inter text-sm"
								disabled={actionLoading || !countryCode}
							>
								{actionLoading ? "Adding.." : "Add"}
							</Button>
						)}
					</Modal.Footer>
				</Form>
			</Modal>
			<Modal
				show={removeEmailModal}
				onHide={() => handleRemoveEmailClose()}
				centered
			>
				<Modal.Body>
					<h2 className="text-lg text-medium-inter">
						Disable Email Forwarding
					</h2>
					<p className="text-sm opacity-5 mb-0">
						Are you sure you want to disable email forwarding for this phone
						number?
					</p>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="light"
						className="modal-btn btn-sm dismiss-btn text-medium-inter text-sm"
						onClick={() => handleRemoveEmailClose()}
					>
						Cancel
					</Button>
					<Button
						className="modal-btn btn-sm btn-default text-medium-inter text-sm"
						disabled={actionLoading}
						onClick={(e) => removeForwardChannel(e, "email")}
					>
						{actionLoading ? "Disabling.." : "Disable"}
					</Button>
				</Modal.Footer>
			</Modal>
			<Modal
				show={removePushModal}
				onHide={() => handleRemovePushClose()}
				centered
			>
				<Modal.Body>
					<h2 className="text-lg text-medium-inter">Disable Push Forwarding</h2>
					<p className="text-sm opacity-5 mb-0">
						Are you sure you want to disable push forwarding for this phone
						number?
					</p>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="light"
						className="modal-btn btn-sm dismiss-btn text-medium-inter text-sm"
						onClick={() => handleRemovePushClose()}
					>
						Cancel
					</Button>
					<Button
						className="modal-btn btn-sm btn-default text-medium-inter text-sm"
						disabled={actionLoading}
						onClick={(e) => removeForwardChannel(e, "push")}
					>
						{actionLoading ? "Disabling.." : "Disable"}
					</Button>
				</Modal.Footer>
			</Modal>
			<Modal
				show={removeSmsModal}
				onHide={() => handleRemoveSmsClose()}
				centered
			>
				<Modal.Body>
					<h2 className="text-lg text-medium-inter">Disable SMS Forwarding</h2>
					<p className="text-sm opacity-5 mb-0">
						Are you sure you want to disable SMS forwarding for this phone
						number?
					</p>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="light"
						className="modal-btn btn-sm dismiss-btn text-medium-inter text-sm"
						onClick={() => handleRemoveSmsClose()}
					>
						Cancel
					</Button>
					<Button
						className="modal-btn btn-sm btn-default text-medium-inter text-sm"
						disabled={actionLoading}
						onClick={(e) => removeForwardChannel(e, "sms")}
					>
						{actionLoading ? "Disabling.." : "Disable"}
					</Button>
				</Modal.Footer>
			</Modal>
			<Modal
				show={toDeleteMsisdn}
				onHide={() => setToDeleteMsisdn(null)}
				centered
			>
				<Modal.Body>
					<h2 className="text-lg text-medium-inter mb-20">
						Delete Phone Number
					</h2>
					<p className="text-sm opacity-5">
						Deleted phone numbers can be recovered within a month, after which
						they will be permanently deleted and unrecoverable.
					</p>
					<p className="text-sm opacity-5">Would you like to proceed?</p>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="light"
						className="modal-btn btn-sm dismiss-btn text-medium-inter text-sm"
						onClick={() => setToDeleteMsisdn(null)}
					>
						Cancel
					</Button>
					<Button
						className="modal-btn btn-sm btn-danger text-medium-inter text-sm"
						disabled={actionLoading}
						onClick={deleteMsisdn}
					>
						{actionLoading ? "Deleting.." : "Yes, Delete"}
					</Button>
				</Modal.Footer>
			</Modal>
			<Modal
				show={subscribeModal}
				onHide={() => setSubscribeModal(false)}
				centered
			>
				<Modal.Header className="text-medium-inter text-lg flex-row-left">
					<i className="ri-error-warning-line text-danger text-xl me-2" />
					Upgrade Account
				</Modal.Header>
				<Modal.Body>
					<p className="text-sm opacity-5 mb-0">{subscribeError}</p>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="light"
						className="modal-btn btn-md dismiss-btn text-medium-inter text-sm"
						onClick={() => setSubscribeModal(false)}
					>
						Cancel
					</Button>
					<Button
						className="modal-btn btn-md btn-default text-medium-inter text-sm"
						onClick={() => navigate(`/plans?redirect_url=/safes/${id}`)}
					>
						Upgrade
					</Button>
				</Modal.Footer>
			</Modal>
			<Modal
				show={assetModal}
				size="lg"
				onHide={closeAssetModal}
				className="asset-modal"
				centered
			>
				<Modal.Header>
					<div>
						<span className="text-medium-inter text-lg">New Asset</span>
						<p className="m-0 text-sm opacity-5">
							Securely store credentials, cards, and more.
						</p>
					</div>
				</Modal.Header>
				<Form onSubmit={handleAssetSubmit}>
					<Modal.Body>
						<Row className="form-block-sm">
							<Col>
								<Form.Label>Asset Type</Form.Label>
								<div>
									<div
										className={`asset-type-picker border ${
											assetType === "Login" && "active"
										}`}
										onClick={() => setAssetType("Login")}
									>
										<div className="flex-row-left">
											<i className="ri-user-line" />
											<span className="label-md">Login</span>
										</div>
									</div>
									<div
										className={`asset-type-picker flex-row-left border ${
											assetType === "MFA" && "active"
										}`}
										onClick={() => setAssetType("MFA")}
									>
										<div className="flex-row-left">
											<i className="ri-key-2-line" />
											<span className="label-md">2FA Code</span>
										</div>
									</div>
									<div
										className={`asset-type-picker flex-row-left border ${
											assetType === "Card" && "active"
										}`}
										onClick={() => setAssetType("Card")}
									>
										<div className="flex-row-left">
											<i className="ri-bank-card-2-line" />
											<span className="label-md">Card</span>
										</div>
									</div>
									<div
										className={`asset-type-picker flex-row-left border ${
											assetType === "Note" && "active"
										}`}
										onClick={() => setAssetType("Note")}
									>
										<div className="flex-row-left">
											<i className="ri-sticky-note-line" />
											<span className="label-md">Note</span>
										</div>
									</div>
									<div
										className={`asset-type-picker flex-row-left border ${
											assetType === "Address" && "active"
										}`}
										onClick={() => setAssetType("Address")}
									>
										<div className="flex-row-left">
											<i className="ri-map-pin-2-line" />
											<span className="label-md">Address</span>
										</div>
									</div>
									<div
										className={`asset-type-picker flex-row-left border ${
											assetType === "Contact" && "active"
										}`}
										onClick={() => setAssetType("Contact")}
									>
										<div className="flex-row-left">
											<i className="ri-user-line" />
											<span className="label-md">Contact</span>
										</div>
									</div>
									<div
										className={`asset-type-picker flex-row-left border ${
											assetType === "API" && "active"
										}`}
										onClick={() => setAssetType("API")}
									>
										<div className="flex-row-left">
											<i className="ri-code-s-slash-line" />
											<span className="label-md">API Credentials</span>
										</div>
									</div>
									<div
										className={`asset-type-picker flex-row-left border ${
											assetType === "Wifi" && "active"
										}`}
										onClick={() => setAssetType("Wifi")}
									>
										<div className="flex-row-left">
											<i className="ri-wifi-line" />
											<span className="label-md">WiFi</span>
										</div>
									</div>
								</div>
							</Col>
						</Row>
						<Row className="form-block-sm">
							<Col>
								<Form.Label>Asset Name*</Form.Label>
								<Form.Control
									type="text"
									className={`form-input input-lg text-sm rounded-4 ${
										assetErrors.includes("assetName") && "border-danger"
									}`}
									placeholder="Enter asset name."
									value={assetName}
									onChange={(e) => setAssetName(e.target.value)}
									disabled={acceptAsset}
									autoComplete="off"
								/>
							</Col>
						</Row>
						{assetType === "Login" && (
							<>
								<Row className="form-block-sm">
									<Col>
										<Form.Label>
											URL <span className="opacity-5 text-sm">(Optional)</span>
										</Form.Label>
										<Form.Control
											type="text"
											className={`form-input input-lg text-sm rounded-4 ${
												assetErrors.includes("assetUrl") && "border-danger"
											}`}
											placeholder="Enter website URL."
											value={assetUrl}
											onChange={(e) => setAssetUrl(e.target.value)}
											autoComplete="off"
										/>
									</Col>
								</Row>
								<Row className="form-block-sm">
									<Col>
										<Form.Label>Login Type*</Form.Label>
										<Form.Select
											className={`form-input input-lg text-sm rounded-4 ${
												assetErrors.includes("assetLoginType") &&
												"border-danger"
											}`}
											value={assetLoginType}
											onChange={(e) => setAssetLoginType(e.target.value)}
										>
											<option value="username">Username & Password</option>
											<option value="sso">SSO</option>
										</Form.Select>
									</Col>
								</Row>
								{assetLoginType === "username" && (
									<>
										<Row className="form-block-sm">
											<Col>
												<Form.Label>Username*</Form.Label>
												<div className="flex-row-left full">
													<Form.Control
														type="text"
														className={`asset-username form-input input-lg text-sm rounded-4 ${
															assetErrors.includes("username") &&
															"border-danger"
														}`}
														placeholder="Enter username/email."
														value={username}
														onChange={(e) => setUsername(e.target.value)}
														autoComplete="off"
													/>
													<div className="asset-pw-actions flex-row-right">
														<Button
															variant="light flex-column"
															onClick={() => copyAssetItem(null, username)}
														>
															<i className="ri-file-copy-line opacity-7" />
														</Button>
													</div>
												</div>
											</Col>
										</Row>
										<Row className="form-block-sm">
											<Col>
												<Form.Label>Password*</Form.Label>
												<div className="flex-row-left full">
													<Form.Control
														type={showPassword ? "text" : "password"}
														className={`asset-password form-input input-lg text-sm rounded-4 ${
															assetErrors.includes("password") &&
															"border-danger"
														}`}
														placeholder="Enter password."
														value={password}
														onChange={(e) => setPassword(e.target.value)}
														autoComplete="off"
													/>
													<div className="asset-pw-actions flex-row-right">
														<Button
															variant="light flex-column"
															onClick={() => setShowPassword(!showPassword)}
														>
															<i
																className={`opacity-7 ${
																	showPassword
																		? "ri-eye-off-line"
																		: "ri-eye-line"
																}`}
															/>
														</Button>
														<Button
															variant="light flex-column"
															onClick={generatePassword}
														>
															<i className="ri-refresh-line opacity-7" />
														</Button>
														<Button
															variant="light flex-column"
															onClick={() => copyAssetItem(null, password)}
														>
															<i className="ri-file-copy-line opacity-7" />
														</Button>
													</div>
												</div>
											</Col>
										</Row>
										<Row className="form-block-sm">
											<Col>
												<Form.Label>
													Authenticator Key (TOTP){" "}
													<span className="opacity-5 text-sm">(Optional)</span>
												</Form.Label>
												<div className="flex-row-left full">
													<Form.Control
														type="text"
														className={`asset-code form-input input-lg text-sm rounded-4 ${
															assetErrors.includes("assetCode") &&
															"border-danger"
														}`}
														placeholder="Enter 2FA setup key."
														value={assetCode}
														onChange={(e) => setAssetCode(e.target.value)}
														autoComplete="off"
													/>
													<div className="asset-pw-actions flex-row-right">
														<input
															ref={fileRef}
															type="file"
															accept="image/png, image/jpeg"
															onChange={handleFileInputChange}
															className="hidden"
														/>
														<Button
															variant="light flex-column"
															onClick={() => fileRef.current.click()}
														>
															<i className="ri-qr-code-line opacity-7" />
														</Button>
													</div>
												</div>
											</Col>
										</Row>
										<Row className="form-block-sm">
											<Col>
												<Form.Label>
													SMSafe Number{" "}
													<span className="opacity-5 text-sm">(Optional)</span>
												</Form.Label>
												<Form.Select
													className={`form-input input-lg text-sm rounded-4 ${
														assetErrors.includes("assetMsisdn") &&
														"border-danger"
													}`}
													placeholder="Add notes"
													value={assetMsisdn}
													onChange={(e) => setAssetMsisdn(e.target.value)}
												>
													<option value=""></option>
													{groupMsisdns?.map((groupMsisdn) => (
														<option
															key={groupMsisdn.id}
															value={groupMsisdn.v_msisdn.msisdn}
														>
															{groupMsisdn.v_msisdn.msisdn}
														</option>
													))}
												</Form.Select>
											</Col>
										</Row>
									</>
								)}
								{assetLoginType === "sso" && (
									<Row className="form-block-sm">
										<Col>
											<Form.Label>SSO Asset*</Form.Label>
											<Select
												className={`form-input form-select-input input-lg text-sm rounded-4 ${
													assetErrors.includes("ssoAsset") && "border-danger"
												}`}
												classNamePrefix="form-select"
												defaultValue={ssoAsset}
												onChange={setSSOAsset}
												options={assets
													.filter(
														(asset) =>
															asset.type_ === "Login" &&
															asset.login_type === "username"
													)
													.map(({ id, name, username }) => ({
														value: id,
														label: `${name} ${
															username ? "(" + username + ")" : ""
														}`,
													}))}
											/>
										</Col>
									</Row>
								)}
								<Row className="form-block-sm">
									<Col>
										<Form.Label>
											Notes{" "}
											<span className="opacity-5 text-sm">(Optional)</span>
										</Form.Label>
										<Form.Control
											as="textarea"
											className={`form-input input-lg text-sm rounded-4 ${
												assetErrors.includes("assetNotes") && "border-danger"
											}`}
											placeholder="Add notes"
											value={assetNotes}
											onChange={(e) => setAssetNotes(e.target.value)}
											autoComplete="off"
										/>
									</Col>
								</Row>
							</>
						)}
						{assetType === "MFA" && (
							<Row className="form-block-sm">
								<Col>
									<Form.Label>Authenticator Key (TOTP)*</Form.Label>
									<div className="flex-row-left full">
										<Form.Control
											type="text"
											className={`asset-code form-input input-lg text-sm rounded-4 ${
												assetErrors.includes("assetCode") && "border-danger"
											}`}
											placeholder="Enter 2FA setup key."
											value={assetCode}
											onChange={(e) => setAssetCode(e.target.value)}
											autoComplete="off"
										/>
										<div className="asset-pw-actions flex-row-right">
											<input
												ref={fileRef}
												type="file"
												accept="image/png, image/jpeg"
												onChange={handleFileInputChange}
												className="hidden"
											/>
											<Button
												variant="light flex-column"
												onClick={() => fileRef.current.click()}
											>
												<i className="ri-qr-code-line opacity-7" />
											</Button>
										</div>
									</div>
								</Col>
							</Row>
						)}
						{assetType === "Card" && (
							<>
								<Row className="form-block-sm">
									<Col>
										<Form.Label>Card Number*</Form.Label>
										<div className="flex-row-left pull">
											<Form.Control
												type="text"
												className={`asset-card-info form-input input-lg text-sm rounded-4 ${
													assetErrors.includes("cardNo") && "border-danger"
												}`}
												placeholder="Enter card number."
												value={cardNo}
												onChange={handleCardNoChange}
												autoComplete="off"
											/>
											<div className="asset-pw-actions flex-row-right">
												<Button
													variant="light flex-column"
													onClick={() =>
														copyAssetItem(null, cardNo.replace(/\s/g, ""))
													}
												>
													<i className="ri-file-copy-line opacity-7" />
												</Button>
											</div>
										</div>
									</Col>
								</Row>
								<Row className="form-block-sm">
									<Col>
										<Form.Label>Expiration*</Form.Label>
										<div className="flex-row-left full">
											<Form.Control
												type="text"
												className={`asset-card-info form-input input-lg text-sm rounded-4 ${
													assetErrors.includes("cardExpiry") && "border-danger"
												}`}
												placeholder="Enter card expiration."
												value={cardExpiry}
												onChange={handleCardExpiryChange}
												autoComplete="off"
											/>
											<div className="asset-pw-actions flex-row-right">
												<Button
													variant="light flex-column"
													onClick={() => copyAssetItem(null, cardExpiry)}
												>
													<i className="ri-file-copy-line opacity-7" />
												</Button>
											</div>
										</div>
									</Col>
									<Col>
										<Form.Label>Security Code*</Form.Label>
										<div className="flex-row-left full">
											<Form.Control
												type="password"
												className={`asset-card-info form-input input-lg text-sm rounded-4 ${
													assetErrors.includes("cardCode") && "border-danger"
												}`}
												placeholder="Enter security code."
												value={cardCode}
												onChange={(e) => setCardCode(e.target.value)}
												autoComplete="off"
											/>
											<div className="asset-pw-actions flex-row-right">
												<Button
													variant="light flex-column"
													onClick={() => copyAssetItem(null, cardCode)}
												>
													<i className="ri-file-copy-line opacity-7" />
												</Button>
											</div>
										</div>
									</Col>
								</Row>
								<Row className="form-block-sm">
									<Col>
										<Form.Label>Name on Card*</Form.Label>
										<div className="flex-row-left full">
											<Form.Control
												type="text"
												className={`asset-card-info form-input input-lg text-sm rounded-4 ${
													assetErrors.includes("cardName") && "border-danger"
												}`}
												placeholder="Enter name on card."
												value={cardName}
												onChange={(e) => setCardName(e.target.value)}
												autoComplete="off"
											/>
											<div className="asset-pw-actions flex-row-right">
												<Button
													variant="light flex-column"
													onClick={() => copyAssetItem(null, cardName)}
												>
													<i className="ri-file-copy-line opacity-7" />
												</Button>
											</div>
										</div>
									</Col>
								</Row>
							</>
						)}
						{assetType === "Address" && (
							<>
								<Row className="form-block-sm">
									<Col>
										<Form.Label>Street Address*</Form.Label>
										<div className="flex-row-left full">
											<Form.Control
												as="textarea"
												className={`asset-address form-input input-lg text-sm rounded-4 ${
													assetErrors.includes("street") && "border-danger"
												}`}
												placeholder="Enter street address."
												value={street}
												onChange={(e) => setStreet(e.target.value)}
												autoComplete="off"
											/>
											<div className="asset-textarea-actions flex-row-right">
												<Button
													variant="light flex-column"
													onClick={() => copyAssetItem(null, street)}
												>
													<i className="ri-file-copy-line opacity-7" />
												</Button>
											</div>
										</div>
									</Col>
								</Row>
								<Row className="form-block-sm">
									<Col>
										<Form.Label>City/Town*</Form.Label>
										<div className="flex-row-left full">
											<Form.Control
												type="text"
												className={`asset-address form-input input-lg text-sm rounded-4 ${
													assetErrors.includes("city") && "border-danger"
												}`}
												placeholder="Enter city/town."
												value={city}
												onChange={(e) => setCity(e.target.value)}
												autoComplete="off"
											/>
											<div className="asset-pw-actions flex-row-right">
												<Button
													variant="light flex-column"
													onClick={() => copyAssetItem(null, city)}
												>
													<i className="ri-file-copy-line opacity-7" />
												</Button>
											</div>
										</div>
									</Col>
									<Col>
										<Form.Label>State/Province*</Form.Label>
										<div className="flex-row-left full">
											<Form.Control
												type="text"
												className={`asset-address form-input input-lg text-sm rounded-4 ${
													assetErrors.includes("province") && "border-danger"
												}`}
												placeholder="Enter state/province."
												value={province}
												onChange={(e) => setProvince(e.target.value)}
												autoComplete="off"
											/>
											<div className="asset-pw-actions flex-row-right">
												<Button
													variant="light flex-column"
													onClick={() => copyAssetItem(null, province)}
												>
													<i className="ri-file-copy-line opacity-7" />
												</Button>
											</div>
										</div>
									</Col>
								</Row>
								<Row className="form-block-sm">
									<Col>
										<Form.Label>Country*</Form.Label>
										<div className="flex-row-left full">
											<Form.Control
												type="text"
												className={`asset-address form-input input-lg text-sm rounded-4 ${
													assetErrors.includes("country") && "border-danger"
												}`}
												placeholder="Enter country."
												value={country}
												onChange={(e) => setCountry(e.target.value)}
												autoComplete="off"
											/>
											<div className="asset-pw-actions flex-row-right">
												<Button
													variant="light flex-column"
													onClick={() => copyAssetItem(null, country)}
												>
													<i className="ri-file-copy-line opacity-7" />
												</Button>
											</div>
										</div>
									</Col>
									<Col>
										<Form.Label>Postal Code*</Form.Label>
										<div className="flex-row-left full">
											<Form.Control
												type="text"
												className={`asset-address form-input input-lg text-sm rounded-4 ${
													assetErrors.includes("postalCode") && "border-danger"
												}`}
												placeholder="Enter postal code."
												value={postalCode}
												onChange={(e) => setPostalCode(e.target.value)}
												autoComplete="off"
											/>
											<div className="asset-pw-actions flex-row-right">
												<Button
													variant="light flex-column"
													onClick={() => copyAssetItem(null, postalCode)}
												>
													<i className="ri-file-copy-line opacity-7" />
												</Button>
											</div>
										</div>
									</Col>
								</Row>
							</>
						)}
						{assetType === "API" && (
							<>
								<Row className="form-block-sm">
									<Col>
										<Form.Label>API Key*</Form.Label>
										<div className="flex-row-left full">
											<Form.Control
												type="text"
												className={`asset-card-info form-input input-lg text-sm rounded-4 ${
													assetErrors.includes("apiKey") && "border-danger"
												}`}
												placeholder="Enter API key."
												value={apiKey}
												onChange={(e) => setApiKey(e.target.value)}
												autoComplete="off"
											/>
											<div className="asset-pw-actions flex-row-right">
												<Button
													variant="light flex-column"
													onClick={() => copyAssetItem(null, apiKey)}
												>
													<i className="ri-file-copy-line opacity-7" />
												</Button>
											</div>
										</div>
									</Col>
								</Row>
								<Row className="form-block-sm">
									<Col>
										<Form.Label>
											Secret{" "}
											<span className="opacity-5 text-sm">(Optional)</span>
										</Form.Label>
										<div className="flex-row-left full">
											<Form.Control
												type="text"
												className={`asset-card-info form-input input-lg text-sm rounded-4 ${
													assetErrors.includes("apiSecret") && "border-danger"
												}`}
												placeholder="Enter secret key."
												value={apiSecret}
												onChange={(e) => setApiSecret(e.target.value)}
												autoComplete="off"
											/>
											<div className="asset-pw-actions flex-row-right">
												<Button
													variant="light flex-column"
													onClick={() => copyAssetItem(null, apiSecret)}
												>
													<i className="ri-file-copy-line opacity-7" />
												</Button>
											</div>
										</div>
									</Col>
								</Row>
								<Row className="form-block-sm">
									<Col>
										<Form.Label>
											Documentation{" "}
											<span className="opacity-5 text-sm">(Optional)</span>
										</Form.Label>
										<div className="flex-row-left full">
											<Form.Control
												type="text"
												className={`asset-card-info form-input input-lg text-sm rounded-4 ${
													assetErrors.includes("docs") && "border-danger"
												}`}
												placeholder="Enter documentation URL."
												value={docs}
												onChange={(e) => setDocs(e.target.value)}
												autoComplete="off"
											/>
										</div>
									</Col>
								</Row>
							</>
						)}
						{assetType === "Contact" && (
							<>
								<Row className="form-block-sm">
									<Col>
										<Form.Label>Contact Name*</Form.Label>
										<div className="flex-row-left full">
											<Form.Control
												type="text"
												className={`asset-card-info form-input input-lg text-sm rounded-4 ${
													assetErrors.includes("contactName") && "border-danger"
												}`}
												placeholder="Enter contact name."
												value={contactName}
												onChange={(e) => setContactName(e.target.value)}
												autoComplete="off"
											/>
										</div>
									</Col>
								</Row>
								<Row className="form-block-sm">
									<Col>
										<Form.Label>
											Email{" "}
											<span className="opacity-5 text-sm">(Optional)</span>
										</Form.Label>
										<div className="flex-row-left full">
											<Form.Control
												type="email"
												className={`asset-card-info form-input input-lg text-sm rounded-4 ${
													assetErrors.includes("contactEmail") &&
													"border-danger"
												}`}
												placeholder="Enter contact email."
												value={contactEmail}
												onChange={(e) => setContactEmail(e.target.value)}
												autoComplete="off"
											/>
										</div>
									</Col>
									<Col>
										<Form.Label>
											Contact Number{" "}
											<span className="opacity-5 text-sm">(Optional)</span>
										</Form.Label>
										<div className="flex-row-left full">
											<Form.Control
												type="text"
												className={`asset-card-info form-input input-lg text-sm rounded-4 ${
													assetErrors.includes("contactMsisdn") &&
													"border-danger"
												}`}
												placeholder="Enter contact number."
												value={contactMsisdn}
												onChange={(e) => setContactMsisdn(e.target.value)}
												autoComplete="off"
											/>
										</div>
									</Col>
								</Row>
							</>
						)}
						{assetType === "Wifi" && (
							<>
								<Row className="form-block-sm">
									<Col>
										<Form.Label>SSID (Network Name)*</Form.Label>
										<div className="flex-row-left full">
											<Form.Control
												type="text"
												className={`asset-card-info form-input input-lg text-sm rounded-4 ${
													assetErrors.includes("wifiName") && "border-danger"
												}`}
												placeholder="Enter contact number."
												value={wifiName}
												onChange={(e) => setWifiName(e.target.value)}
												autoComplete="off"
											/>
										</div>
									</Col>
								</Row>
								<Row className="form-block-sm">
									<Col>
										<Form.Label>Password*</Form.Label>
										<div className="flex-row-left full">
											<Form.Control
												type={showWifiPassword ? "text" : "password"}
												className={`asset-password form-input input-lg text-sm rounded-4 ${
													assetErrors.includes("wifiPassword") &&
													"border-danger"
												}`}
												placeholder="Enter wifi password."
												value={wifiPassword}
												onChange={(e) => setWifiPassword(e.target.value)}
												autoComplete="off"
											/>
											<div className="asset-pw-actions flex-row-right">
												<Button
													variant="light flex-column"
													onClick={() => setShowWifiPassword(!showWifiPassword)}
												>
													<i
														className={`opacity-7 ${
															showWifiPassword
																? "ri-eye-off-line"
																: "ri-eye-line"
														}`}
													/>
												</Button>
											</div>
										</div>
									</Col>
								</Row>
								<Row className="form-block-sm">
									<Col>
										<Form.Label>Encryption Type*</Form.Label>
										<div className="flex-row-left full">
											<Form.Select
												className={`asset-card-info form-input input-lg text-sm rounded-4 ${
													assetErrors.includes("wifiEncryption") &&
													"border-danger"
												}`}
												placeholder="Enter contact number."
												value={wifiEncryption}
												onChange={(e) => setWifiEncryption(e.target.value)}
											>
												<option value="WPA2">WPA2</option>
												<option value="WPA">WPA</option>
												<option value="nopass">No Password</option>
											</Form.Select>
										</div>
									</Col>
								</Row>
								<Row className="form-block-sm">
									<Col>
										<Form.Label>Hidden Network*</Form.Label>
										<div className="flex-row-left full">
											<Form.Select
												className={`asset-card-info form-input input-lg text-sm rounded-4 ${
													assetErrors.includes("wifiIsHidden") &&
													"border-danger"
												}`}
												placeholder="Enter contact number."
												value={wifiIsHidden}
												onChange={(e) => setWifiIsHidden(e.target.value)}
											>
												<option value={false}>No</option>
												<option value={true}>Yes</option>
											</Form.Select>
										</div>
									</Col>
								</Row>
							</>
						)}
						{assetType === "Note" && (
							<>
								<Row className="form-block-sm">
									<Col>
										<Form.Label>Note*</Form.Label>
										<Form.Control
											as="textarea"
											className={`form-input input-lg text-sm rounded-4 ${
												assetErrors.includes("assetNotes") && "border-danger"
											}`}
											placeholder="Add notes"
											value={assetNotes}
											onChange={(e) => setAssetNotes(e.target.value)}
											autoComplete="off"
										/>
									</Col>
								</Row>
							</>
						)}
						{acceptAsset && (
							<>
								<div className="asset-access-settings border-top">
									<h2 className="text-lg mb-20">Access Settings</h2>
								</div>
								<Row className="mb-20">
									<Col>
										<span className="text-sm text-medium-inter">
											Allow Editing:
										</span>
									</Col>
									<Col>
										<input
											type="checkbox"
											defaultChecked={allowEdit}
											onChange={(e) => setAllowEdit(e.target.checked)}
											className="cbx asset-cbx hidden"
											id="allowEditing"
										/>
										<label
											htmlFor="allowEditing"
											className="lbl asset-lbl"
										></label>
									</Col>
								</Row>
								<Row className="mb-20">
									<Col>
										<span className="text-sm text-medium-inter">
											Expiration:
										</span>
									</Col>
									<Col>
										<Form.Select
											size="sm"
											value={expiration}
											onChange={(e) => setExpiration(e.target.value)}
										>
											<option value="">None</option>
											<option value="5">5 Minutes</option>
											<option value="15">15 Minutes</option>
											<option value="30">30 Minutes</option>
											<option value="60">1 Hour</option>
											<option value="720">12 Hours</option>
											<option value="1440">1 Day</option>
										</Form.Select>
									</Col>
								</Row>
							</>
						)}
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="light"
							className="modal-btn btn-md dismiss-btn text-medium-inter text-sm"
							onClick={closeAssetModal}
						>
							Cancel
						</Button>
						<Button
							type="submit"
							className="modal-btn btn-md btn-default text-medium-inter text-sm"
							disabled={actionLoading}
						>
							{actionLoading ? "Saving.." : "Save"}
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
			<Modal
				show={assetInfoModal}
				size="lg"
				onHide={closeAssetModal}
				className="asset-modal"
				centered
			>
				<Modal.Header>
					<div>
						<span className="text-medium-inter text-lg">Asset Info</span>
					</div>
					{isOwner && (
						<Dropdown align="end">
							<Dropdown.Toggle as={assetOptionToggle}>
								<i className="ri-more-2-fill" />
							</Dropdown.Toggle>
							<Dropdown.Menu className="account-dropdown dropdown-default">
								<Dropdown.Item
									href="#"
									className="text-sm text-danger"
									onClick={(e) => {
										e.preventDefault();
										setDeleteAssetId(assetInfo.id);
									}}
								>
									Delete
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					)}
				</Modal.Header>
				<Modal.Body>
					{assetLoading ? (
						<div className="flex-column full-height">
							<Spinner className="text-blue" />
						</div>
					) : (
						<>
							{isOwner && (
								<div className={`asset-tabs flex-row-left mb-30 ${assetTab}`}>
									<div>
										<a
											href="#"
											className={`tab flex-column full ${
												assetTab === "details" && "active"
											}`}
											onClick={(e) => {
												e.preventDefault();
												setAssetTab("details");
											}}
										>
											<span className="text-sm text-medium-inter opacity-7">
												Details
											</span>
										</a>
									</div>
									<div>
										<a
											href="#"
											className={`tab flex-column full ${
												assetTab === "sharing" && "active"
											}`}
											onClick={(e) => {
												e.preventDefault();
												setAssetTab("sharing");
											}}
										>
											<span className="text-sm text-medium-inter opacity-7">
												Sharing
											</span>
										</a>
									</div>
									<div>
										<a
											href="#"
											className={`tab flex-column full ${
												assetTab === "activity" && "active"
											}`}
											onClick={(e) => {
												e.preventDefault();
												setAssetTab("activity");
											}}
										>
											<span className="text-sm text-medium-inter opacity-7">
												Activity
											</span>
										</a>
									</div>
								</div>
							)}
							{assetTab === "details" && (
								<>
									{assetType === "Wifi" && (
										<Row className="form-block-sm pt-4">
											<Col className="flex-column">
												<QRCode
													value={`WIFI:T:${wifiEncryption};S:${wifiName};P:${wifiPassword};H:${wifiIsHidden};;`}
													className="wifi-qr mb-10"
												/>
												<span className="text-sm opacity-7">
													Scan this QR to connect.
												</span>
											</Col>
										</Row>
									)}
									<Row className="form-block-sm">
										<Col>
											<Form.Label>Asset Name*</Form.Label>
											<Form.Control
												type="text"
												className={`form-input input-lg text-sm rounded-4 ${
													assetErrors.includes("assetName") && "border-danger"
												}`}
												placeholder={canEdit ? "Enter asset name." : ""}
												value={assetName}
												onChange={(e) => setAssetName(e.target.value)}
												disabled={!canEdit}
												autoComplete="off"
											/>
										</Col>
									</Row>
									{assetType === "Login" && (
										<>
											<Row className="form-block-sm">
												<Col>
													<Form.Label>
														URL{" "}
														<span className="opacity-5 text-sm">
															(Optional)
														</span>
													</Form.Label>
													<Form.Control
														type="text"
														className={`form-input input-lg text-sm rounded-4 ${
															assetErrors.includes("assetUrl") &&
															"border-danger"
														}`}
														placeholder={canEdit ? "Enter website URL" : ""}
														value={assetUrl}
														onChange={(e) => setAssetUrl(e.target.value)}
														disabled={!canEdit}
														autoComplete="off"
													/>
												</Col>
											</Row>
											<Row className="form-block-sm">
												<Col>
													<Form.Label>Login Type*</Form.Label>
													<Form.Select
														type="text"
														className={`form-input input-lg text-sm rounded-4 ${
															assetErrors.includes("assetLoginType") &&
															"border-danger"
														}`}
														value={assetLoginType}
														onChange={(e) => setAssetLoginType(e.target.value)}
														disabled={!canEdit}
													>
														<option value="username">
															Username & Password
														</option>
														<option value="sso">SSO</option>
													</Form.Select>
												</Col>
											</Row>
											{assetLoginType === "username" && (
												<>
													<Row className="form-block-sm">
														<Col>
															<Form.Label>Username*</Form.Label>
															<div className="flex-row-left full">
																<Form.Control
																	type="text"
																	className={`asset-username form-input input-lg text-sm rounded-4 ${
																		assetErrors.includes("username") &&
																		"border-danger"
																	}`}
																	placeholder={
																		canEdit ? "Enter username/email" : ""
																	}
																	value={username}
																	onChange={(e) => setUsername(e.target.value)}
																	disabled={!canEdit}
																	autoComplete="off"
																/>
																<div className="asset-pw-actions flex-row-right">
																	<Button
																		variant="light flex-column"
																		onClick={() =>
																			copyAssetItem(null, username)
																		}
																	>
																		<i className="ri-file-copy-line opacity-7" />
																	</Button>
																</div>
															</div>
														</Col>
													</Row>
													<Row className="form-block-sm">
														<Col>
															<Form.Label>Password*</Form.Label>
															<div className="flex-row-left full">
																<Form.Control
																	type={showPassword ? "text" : "password"}
																	className={`asset-password form-input input-lg text-sm rounded-4 ${
																		assetErrors.includes("password") &&
																		"border-danger"
																	}`}
																	placeholder={canEdit ? "Enter password." : ""}
																	value={password}
																	onChange={(e) => setPassword(e.target.value)}
																	disabled={!canEdit}
																	autoComplete="off"
																/>
																<div className="asset-pw-actions flex-row-right">
																	<Button
																		variant="light flex-column"
																		onClick={() =>
																			setShowPassword(!showPassword)
																		}
																	>
																		<i
																			className={`opacity-7 ${
																				showPassword
																					? "ri-eye-off-line"
																					: "ri-eye-line"
																			}`}
																		/>
																	</Button>
																	{canEdit && (
																		<Button
																			variant="light flex-column"
																			onClick={generatePassword}
																		>
																			<i className="ri-refresh-line opacity-7" />
																		</Button>
																	)}
																	<Button
																		variant="light flex-column"
																		onClick={() =>
																			copyAssetItem(null, password)
																		}
																	>
																		<i className="ri-file-copy-line opacity-7" />
																	</Button>
																</div>
															</div>
														</Col>
													</Row>
													<Row className="form-block-sm">
														<Col>
															<Form.Label>
																Authenticator Key (TOTP){" "}
																<span className="opacity-5 text-sm">
																	(Optional)
																</span>
															</Form.Label>
															{assetInfo?.mfa_token && !showAssetCode ? (
																<AssetMFA
																	key={assetInfo.mfa_token.id}
																	mfa={assetInfo.mfa_token}
																	showCode={setShowAssetCode}
																	canEdit={canEdit}
																/>
															) : (
																<div className="flex-row-left full">
																	<Form.Control
																		type="text"
																		className={`asset-code form-input input-lg text-sm rounded-4 ${
																			assetErrors.includes("assetCode") &&
																			"border-danger"
																		}`}
																		placeholder={
																			canEdit ? "Enter 2FA setup key." : ""
																		}
																		value={assetCode}
																		onChange={(e) =>
																			setAssetCode(e.target.value)
																		}
																		autoComplete="off"
																	/>
																	<div className="asset-pw-actions flex-row-right">
																		<input
																			ref={fileRef}
																			type="file"
																			accept="image/png, image/jpeg"
																			onChange={handleFileInputChange}
																			className="hidden"
																		/>
																		{isOwner && (
																			<Button
																				variant="light flex-column"
																				onClick={() => fileRef.current.click()}
																			>
																				<i className="ri-qr-code-line opacity-7" />
																			</Button>
																		)}
																	</div>
																</div>
															)}
														</Col>
													</Row>
													<Row className="form-block-sm">
														<Col>
															<Form.Label>
																Phone Number{" "}
																<span className="opacity-5 text-sm">
																	(Optional)
																</span>
															</Form.Label>
															<Form.Select
																className={`form-input input-lg text-sm rounded-4 ${
																	assetErrors.includes("assetMsisdn") &&
																	"border-danger"
																}`}
																placeholder={
																	canEdit ? "Enter virtual phone number." : ""
																}
																value={assetMsisdn}
																onChange={(e) => setAssetMsisdn(e.target.value)}
																disabled={!canEdit}
															>
																<option value=""></option>
																{groupMsisdns?.map((groupMsisdn) => (
																	<option value={groupMsisdn.v_msisdn.msisdn}>
																		{groupMsisdn.v_msisdn.msisdn}
																	</option>
																))}
															</Form.Select>
														</Col>
													</Row>
												</>
											)}
											{assetLoginType === "sso" && (
												<Row className="form-block-sm">
													<Col>
														<Form.Label>SSO Asset*</Form.Label>
														<Select
															className={`form-input form-select-input input-lg text-sm rounded-4 ${
																assetErrors.includes("ssoAsset") &&
																"border-danger"
															}`}
															classNamePrefix="form-select"
															defaultValue={ssoAsset}
															onChange={setSSOAsset}
															disabled={!canEdit}
															options={assets
																.filter(
																	(asset) =>
																		asset.type_ === "Login" &&
																		asset.login_type === "username" &&
																		asset.id !== assetInfo?.id
																)
																.map(({ id, name, username }) => ({
																	value: id,
																	label: `${name} ${
																		username ? "(" + username + ")" : ""
																	}`,
																}))}
														/>
													</Col>
												</Row>
											)}
											<Row className="form-block-sm">
												<Col>
													<Form.Label>
														Notes{" "}
														<span className="opacity-5 text-sm">
															(Optional)
														</span>
													</Form.Label>
													<Form.Control
														as="textarea"
														className="form-input asset-notes text-sm rounded-4"
														placeholder={canEdit ? "Add notes" : ""}
														value={assetNotes}
														onChange={(e) => setAssetNotes(e.target.value)}
														disabled={!canEdit}
														autoComplete="off"
													/>
												</Col>
											</Row>
										</>
									)}
									{assetType === "MFA" && (
										<Row className="form-block-sm">
											<Col>
												<Form.Label>Authenticator Key (TOTP)*</Form.Label>
												{assetInfo?.mfa_token && !showAssetCode ? (
													<AssetMFA
														key={assetInfo.mfa_token.id}
														mfa={assetInfo.mfa_token}
														showCode={setShowAssetCode}
														canEdit={canEdit}
													/>
												) : (
													<div className="flex-row-left full">
														<Form.Control
															type="text"
															className={`asset-code form-input input-lg text-sm rounded-4 ${
																assetErrors.includes("assetCode") &&
																"border-danger"
															}`}
															placeholder={
																canEdit ? "Enter 2FA setup key." : ""
															}
															value={assetCode}
															onChange={(e) => setAssetCode(e.target.value)}
															autoComplete="off"
														/>
														<div className="asset-pw-actions flex-row-right">
															<input
																ref={fileRef}
																type="file"
																accept="image/png, image/jpeg"
																onChange={handleFileInputChange}
																className="hidden"
															/>
															{isOwner && (
																<Button
																	variant="light flex-column"
																	onClick={() => fileRef.current.click()}
																>
																	<i className="ri-qr-code-line opacity-7" />
																</Button>
															)}
														</div>
													</div>
												)}
											</Col>
										</Row>
									)}
									{assetType === "Card" && (
										<>
											<Row className="form-block-sm">
												<Col>
													<Form.Label>Card Number*</Form.Label>
													<div className="flex-row-left pull">
														<Form.Control
															type="text"
															className={`asset-card-info form-input input-lg text-sm rounded-4 ${
																assetErrors.includes("cardNo") &&
																"border-danger"
															}`}
															placeholder={canEdit ? "Enter card number." : ""}
															value={cardNo}
															onChange={handleCardNoChange}
															disabled={!canEdit}
															autoComplete="off"
														/>
														<div className="asset-pw-actions flex-row-right">
															<Button
																variant="light flex-column"
																onClick={() =>
																	copyAssetItem(null, cardNo.replace(/\s/g, ""))
																}
															>
																<i className="ri-file-copy-line opacity-7" />
															</Button>
														</div>
													</div>
												</Col>
											</Row>
											<Row className="form-block-sm">
												<Col>
													<Form.Label>Expiration*</Form.Label>
													<div className="flex-row-left full">
														<Form.Control
															type="text"
															className={`asset-card-info form-input input-lg text-sm rounded-4 ${
																assetErrors.includes("cardExpiry") &&
																"border-danger"
															}`}
															placeholder={
																canEdit ? "Enter card expiration." : ""
															}
															value={cardExpiry}
															onChange={handleCardExpiryChange}
															disabled={!canEdit}
															autoComplete="off"
														/>
														<div className="asset-pw-actions flex-row-right">
															<Button
																variant="light flex-column"
																onClick={() => copyAssetItem(null, cardExpiry)}
															>
																<i className="ri-file-copy-line opacity-7" />
															</Button>
														</div>
													</div>
												</Col>
												<Col>
													<Form.Label>Security Code*</Form.Label>
													<div className="flex-row-left full">
														<Form.Control
															type="password"
															className={`asset-card-info form-input input-lg text-sm rounded-4 ${
																assetErrors.includes("cardCode") &&
																"border-danger"
															}`}
															placeholder={
																canEdit ? "Enter security code." : ""
															}
															value={cardCode}
															onChange={(e) => setCardCode(e.target.value)}
															disabled={!canEdit}
															autoComplete="off"
														/>
														<div className="asset-pw-actions flex-row-right">
															<Button
																variant="light flex-column"
																onClick={() => copyAssetItem(null, cardCode)}
															>
																<i className="ri-file-copy-line opacity-7" />
															</Button>
														</div>
													</div>
												</Col>
											</Row>
											<Row className="form-block-sm">
												<Col>
													<Form.Label>Name on Card*</Form.Label>
													<div className="flex-row-left full">
														<Form.Control
															type="text"
															className={`asset-card-info form-input input-lg text-sm rounded-4 ${
																assetErrors.includes("cardName") &&
																"border-danger"
															}`}
															placeholder={canEdit ? "Enter name on card." : ""}
															value={cardName}
															onChange={(e) => setCardName(e.target.value)}
															disabled={!canEdit}
															autoComplete="off"
														/>
														<div className="asset-pw-actions flex-row-right">
															<Button
																variant="light flex-column"
																onClick={() => copyAssetItem(null, cardName)}
															>
																<i className="ri-file-copy-line opacity-7" />
															</Button>
														</div>
													</div>
												</Col>
											</Row>
										</>
									)}
									{assetType === "Address" && (
										<>
											<Row className="form-block-sm">
												<Col>
													<Form.Label>Street Address*</Form.Label>
													<div className="flex-row-left full">
														<Form.Control
															as="textarea"
															className={`asset-address form-input input-lg text-sm rounded-4 ${
																assetErrors.includes("street") &&
																"border-danger"
															}`}
															placeholder={
																canEdit ? "Enter street address." : ""
															}
															value={street}
															onChange={(e) => setStreet(e.target.value)}
															disabled={!canEdit}
															autoComplete="off"
														/>
														<div className="asset-textarea-actions flex-row-right">
															<Button
																variant="light flex-column"
																onClick={() => copyAssetItem(null, street)}
															>
																<i className="ri-file-copy-line opacity-7" />
															</Button>
														</div>
													</div>
												</Col>
											</Row>
											<Row className="form-block-sm">
												<Col>
													<Form.Label>City/Town*</Form.Label>
													<div className="flex-row-left full">
														<Form.Control
															type="text"
															className={`asset-address form-input input-lg text-sm rounded-4 ${
																assetErrors.includes("city") && "border-danger"
															}`}
															placeholder={canEdit ? "Enter city/town." : ""}
															value={city}
															onChange={(e) => setCity(e.target.value)}
															disabled={!canEdit}
															autoComplete="off"
														/>
														<div className="asset-pw-actions flex-row-right">
															<Button
																variant="light flex-column"
																onClick={() => copyAssetItem(null, city)}
															>
																<i className="ri-file-copy-line opacity-7" />
															</Button>
														</div>
													</div>
												</Col>
												<Col>
													<Form.Label>State/Province*</Form.Label>
													<div className="flex-row-left full">
														<Form.Control
															type="text"
															className={`asset-address form-input input-lg text-sm rounded-4 ${
																assetErrors.includes("province") &&
																"border-danger"
															}`}
															placeholder={
																canEdit ? "Enter state/province." : ""
															}
															value={province}
															onChange={(e) => setProvince(e.target.value)}
															disabled={!canEdit}
															autoComplete="off"
														/>
														<div className="asset-pw-actions flex-row-right">
															<Button
																variant="light flex-column"
																onClick={() => copyAssetItem(null, province)}
															>
																<i className="ri-file-copy-line opacity-7" />
															</Button>
														</div>
													</div>
												</Col>
											</Row>
											<Row className="form-block-sm">
												<Col>
													<Form.Label>Country*</Form.Label>
													<div className="flex-row-left full">
														<Form.Control
															type="text"
															className={`asset-address form-input input-lg text-sm rounded-4 ${
																assetErrors.includes("country") &&
																"border-danger"
															}`}
															placeholder={canEdit ? "Enter country." : ""}
															value={country}
															onChange={(e) => setCountry(e.target.value)}
															disabled={!canEdit}
															autoComplete="off"
														/>
														<div className="asset-pw-actions flex-row-right">
															<Button
																variant="light flex-column"
																onClick={() => copyAssetItem(null, country)}
															>
																<i className="ri-file-copy-line opacity-7" />
															</Button>
														</div>
													</div>
												</Col>
												<Col>
													<Form.Label>Postal Code*</Form.Label>
													<div className="flex-row-left full">
														<Form.Control
															type="text"
															className={`asset-address form-input input-lg text-sm rounded-4 ${
																assetErrors.includes("postalCode") &&
																"border-danger"
															}`}
															placeholder={canEdit ? "Enter postal code." : ""}
															value={postalCode}
															onChange={(e) => setPostalCode(e.target.value)}
															disabled={!canEdit}
															autoComplete="off"
														/>
														<div className="asset-pw-actions flex-row-right">
															<Button
																variant="light flex-column"
																onClick={() => copyAssetItem(null, postalCode)}
															>
																<i className="ri-file-copy-line opacity-7" />
															</Button>
														</div>
													</div>
												</Col>
											</Row>
										</>
									)}
									{assetType === "API" && (
										<>
											<Row className="form-block-sm">
												<Col>
													<Form.Label>API Key*</Form.Label>
													<div className="flex-row-left full">
														<Form.Control
															type="text"
															className={`asset-card-info form-input input-lg text-sm rounded-4 ${
																assetErrors.includes("apiKey") &&
																"border-danger"
															}`}
															placeholder={canEdit ? "Enter API key." : ""}
															value={apiKey}
															onChange={(e) => setApiKey(e.target.value)}
															disabled={!canEdit}
															autoComplete="off"
														/>
														<div className="asset-pw-actions flex-row-right">
															<Button
																variant="light flex-column"
																onClick={() => copyAssetItem(null, apiKey)}
															>
																<i className="ri-file-copy-line opacity-7" />
															</Button>
														</div>
													</div>
												</Col>
											</Row>
											<Row className="form-block-sm">
												<Col>
													<Form.Label>
														Secret{" "}
														<span className="opacity-5 text-sm">
															(Optional)
														</span>
													</Form.Label>
													<div className="flex-row-left full">
														<Form.Control
															type="text"
															className={`asset-card-info form-input input-lg text-sm rounded-4 ${
																assetErrors.includes("apiSecret") &&
																"border-danger"
															}`}
															placeholder={canEdit ? "Enter secret key." : ""}
															value={apiSecret}
															onChange={(e) => setApiSecret(e.target.value)}
															disabled={!canEdit}
															autoComplete="off"
														/>
														<div className="asset-pw-actions flex-row-right">
															<Button
																variant="light flex-column"
																onClick={() => copyAssetItem(null, apiSecret)}
															>
																<i className="ri-file-copy-line opacity-7" />
															</Button>
														</div>
													</div>
												</Col>
											</Row>
											<Row className="form-block-sm">
												<Col>
													<Form.Label>
														Documentation{" "}
														<span className="opacity-5 text-sm">
															(Optional)
														</span>
													</Form.Label>
													<div className="flex-row-left full">
														<Form.Control
															type="text"
															className={`form-input input-lg text-sm rounded-4 ${
																assetErrors.includes("docs") && "border-danger"
															}`}
															placeholder={
																canEdit ? "Enter documentation URL." : ""
															}
															value={docs}
															onChange={(e) => setDocs(e.target.value)}
															disabled={!canEdit}
															autoComplete="off"
														/>
													</div>
												</Col>
											</Row>
										</>
									)}
									{assetType === "Contact" && (
										<>
											<Row className="form-block-sm">
												<Col>
													<Form.Label>Contact Name*</Form.Label>
													<div className="flex-row-left full">
														<Form.Control
															type="text"
															className={`asset-card-info form-input input-lg text-sm rounded-4 ${
																assetErrors.includes("contactName") &&
																"border-danger"
															}`}
															placeholder={canEdit ? "Enter contact name." : ""}
															value={contactName}
															onChange={(e) => setContactName(e.target.value)}
															disabled={!canEdit}
															autoComplete="off"
														/>
													</div>
												</Col>
											</Row>
											<Row className="form-block-sm">
												<Col>
													<Form.Label>
														Email{" "}
														<span className="opacity-5 text-sm">
															(Optional)
														</span>
													</Form.Label>
													<div className="flex-row-left full">
														<Form.Control
															type="email"
															className={`asset-card-info form-input input-lg text-sm rounded-4 ${
																assetErrors.includes("contactEmail") &&
																"border-danger"
															}`}
															placeholder={
																canEdit ? "Enter contact email." : ""
															}
															value={contactEmail}
															onChange={(e) => setContactEmail(e.target.value)}
															disabled={!canEdit}
															autoComplete="off"
														/>
													</div>
												</Col>
												<Col>
													<Form.Label>
														Contact Number{" "}
														<span className="opacity-5 text-sm">
															(Optional)
														</span>
													</Form.Label>
													<div className="flex-row-left full">
														<Form.Control
															type="text"
															className={`asset-card-info form-input input-lg text-sm rounded-4 ${
																assetErrors.includes("contactMsisdn") &&
																"border-danger"
															}`}
															placeholder={
																canEdit ? "Enter contact number." : ""
															}
															value={contactMsisdn}
															onChange={(e) => setContactMsisdn(e.target.value)}
															disabled={!canEdit}
															autoComplete="off"
														/>
													</div>
												</Col>
											</Row>
										</>
									)}
									{assetType === "Wifi" && (
										<>
											<Row className="form-block-sm">
												<Col>
													<Form.Label>SSID (Network Name)*</Form.Label>
													<div className="flex-row-left full">
														<Form.Control
															type="text"
															className={`asset-card-info form-input input-lg text-sm rounded-4 ${
																assetErrors.includes("wifiName") &&
																"border-danger"
															}`}
															placeholder="Enter contact number."
															value={wifiName}
															onChange={(e) => setWifiName(e.target.value)}
															disabled={!canEdit}
															autoComplete="off"
														/>
													</div>
												</Col>
											</Row>
											<Row className="form-block-sm">
												<Col>
													<Form.Label>Password*</Form.Label>
													<div className="flex-row-left full">
														<Form.Control
															type={showWifiPassword ? "text" : "password"}
															className={`asset-password form-input input-lg text-sm rounded-4 ${
																assetErrors.includes("wifiPassword") &&
																"border-danger"
															}`}
															placeholder="Enter wifi password."
															value={wifiPassword}
															onChange={(e) => setWifiPassword(e.target.value)}
															disabled={!canEdit}
															autoComplete="off"
														/>
														<div className="asset-pw-actions flex-row-right">
															<Button
																variant="light flex-column"
																onClick={() =>
																	setShowWifiPassword(!showWifiPassword)
																}
															>
																<i
																	className={`opacity-7 ${
																		showWifiPassword
																			? "ri-eye-off-line"
																			: "ri-eye-line"
																	}`}
																/>
															</Button>
														</div>
													</div>
												</Col>
											</Row>
											<Row className="form-block-sm">
												<Col>
													<Form.Label>Encryption Type*</Form.Label>
													<div className="flex-row-left full">
														<Form.Select
															className={`asset-card-info form-input input-lg text-sm rounded-4 ${
																assetErrors.includes("wifiEncryption") &&
																"border-danger"
															}`}
															placeholder="Enter contact number."
															value={wifiEncryption}
															onChange={(e) =>
																setWifiEncryption(e.target.value)
															}
															disabled={!canEdit}
														>
															<option value="WPA2">WPA2</option>
															<option value="WPA">WPA</option>
															<option value="nopass">No Password</option>
														</Form.Select>
													</div>
												</Col>
											</Row>
											<Row className="form-block-sm">
												<Col>
													<Form.Label>Hidden Network*</Form.Label>
													<div className="flex-row-left full">
														<Form.Select
															className={`asset-card-info form-input input-lg text-sm rounded-4 ${
																assetErrors.includes("wifiIsHidden") &&
																"border-danger"
															}`}
															placeholder="Enter contact number."
															value={wifiIsHidden}
															onChange={(e) => setWifiIsHidden(e.target.value)}
															disabled={!canEdit}
														>
															<option value={false}>No</option>
															<option value={true}>Yes</option>
														</Form.Select>
													</div>
												</Col>
											</Row>
										</>
									)}
								</>
							)}
							{assetType === "Note" && (
								<>
									<Row className="form-block-sm">
										<Col>
											<Form.Label>Note*</Form.Label>
											<Form.Control
												as="textarea"
												className="form-input asset-notes text-sm rounded-4"
												placeholder={canEdit ? "Add notes" : ""}
												value={assetNotes}
												onChange={(e) => setAssetNotes(e.target.value)}
												disabled={!canEdit}
												autoComplete="off"
											/>
										</Col>
									</Row>
								</>
							)}
							{assetTab === "sharing" && (
								<>
									<div className="mb-30">
										<AsyncSelect
											isClearable
											isMulti
											inputValue={shareInput}
											onInputChange={setShareInput}
											noOptionsMessage={() =>
												shareInput ? "No results" : "Search guests"
											}
											loadOptions={loadShareOptions}
											defaultOptions={[]}
											onChange={setShareTo}
											menuShouldScrollIntoView={false}
											maxMenuHeight={200}
											placeholder="Search safe members"
											classNamePrefix="form-select"
											styles={{
												control: (baseStyles, state) => ({
													...baseStyles,
													boxShadow: "none",
													outline: "none",
													borderRadius: 4,
													fontSize: "0.875rem",
												}),
												option: (baseStyles, state) => ({
													...baseStyles,
													fontSize: ".875rem",
												}),
											}}
										/>
									</div>
									{shareTo?.length !== 0 ? (
										<div className="border p-10 rounded">
											{/* <Row className="mb-20">
												<Col className="col-lg-3 col-6">
													<span className="text-sm text-medium-inter">
														Allow Editing:
													</span>
												</Col>
												<Col className="col-lg-9 col-6">
													<input
														type="checkbox"
														defaultChecked={shareAllowEdit}
														onChange={(e) =>
															setShareAllowEdit(e.target.checked)
														}
														className="cbx asset-cbx hidden"
														id="allowEditing"
													/>
													<label
														htmlFor="allowEditing"
														className="lbl asset-lbl"
													></label>
												</Col>
											</Row> */}
											<Row className="mb-10">
												<Col className="col-lg-3 col-6">
													<span className="text-sm text-medium-inter">
														Expiration:
													</span>
												</Col>
												<Col className="col-lg-9 col-6">
													<Form.Select
														size="sm"
														value={shareExpiration}
														onChange={(e) => setShareExpiration(e.target.value)}
													>
														<option value="">None</option>
														<option value="15">15 Minutes</option>
														<option value="30">30 Minutes</option>
														<option value="60">1 Hour</option>
														<option value="720">12 Hours</option>
														<option value="1440">1 Day</option>
													</Form.Select>
												</Col>
											</Row>
										</div>
									) : (
										<>
											<span className="text-medium-inter">
												People with access
											</span>
											<div className="asset-owner">
												<Row className="flex-row-left">
													<Col className="col-lg-8 col-12 flex-row-left">
														<div
															className="asset-member-icon flex-column"
															style={{
																background: assetInfo?.user.photo
																	? `url(${assetInfo?.user.photo})`
																	: "#3c57cc",
															}}
														>
															{!assetInfo?.user.photo && (
																<span className="text-medium-inter text-white text-sm upper">
																	{assetInfo?.user.username
																		? assetInfo?.user.username[0]
																		: assetInfo?.user.email[0]}
																</span>
															)}
														</div>
														{assetInfo?.user.username ? (
															<div className="member-details-container">
																<span className="text-sm ellipsis me-1">
																	{assetInfo?.user.username}
																</span>
																<span className="label-md opacity-5 block ellipsis">
																	{assetInfo?.user.email}
																</span>
															</div>
														) : (
															<div className="member-details-container">
																<span className="text-sm ellipsis">
																	{assetInfo?.user.email}
																</span>
															</div>
														)}
													</Col>
													<Col className="col-lg-4 col-12 member-action flex-row-right">
														<span className="text-sm opacity-5">Owner</span>
													</Col>
												</Row>
											</div>
											<div>
												{sharedTo?.map((member) => (
													<AssetMember
														key={member.id}
														member={member}
														asset={assetInfo}
														setSharedTo={setSharedTo}
														user={user}
													/>
												))}
											</div>
											{assetInfo?.login_type === "username" && (
												<>
													<span className="text-medium-inter mt-4 block mb-10">
														One-time links
													</span>
													{assetLinks?.map((link) => (
														<div
															key={link.id}
															className="asset-link bg-light rounded-4 p-10 flex-row-left mb-15 border"
														>
															<span
																className={`asset-link-status text-white label-md align-center ${link.status}`}
															>
																{link.status}
															</span>
															<span className="text-sm asset-link-name ellipsis">
																{link.email}
															</span>
															<div className="asset-link-action flex-row-right">
																<Button
																	className="asset-link-copy flex-row-center text-medium-inter"
																	onClick={() => copyAssetLink(link.path)}
																>
																	<i className="ri-file-copy-line me-1 text-sm" />
																	<span className="label-md">Copy</span>
																</Button>
															</div>
														</div>
													))}
													{linkForm && (
														<Form onSubmit={generateAssetLink} className="full">
															<div className="asset-link-form bg-light rounded-4 flex-row-left mb-15 border">
																<div className="asset-link-input-container">
																	<Form.Control
																		autoFocus
																		placeholder="Enter email"
																		className="text-sm full"
																		value={linkEmail}
																		onChange={(e) =>
																			setLinkEmail(e.target.value)
																		}
																	/>
																</div>
																<div className="asset-link-action flex-row-right">
																	<Button
																		variant="light"
																		className="flex-row-center me-2"
																		onClick={() => {
																			setLinkForm(false);
																			setLinkEmail("");
																		}}
																	>
																		<span className="label-md">Cancel</span>
																	</Button>
																	<Button
																		type="submit"
																		className="asset-link-copy flex-row-center text-medium-inter"
																		disabled={linkLoading}
																	>
																		<i className="ri-link me-1 text-sm" />
																		<span className="label-md">
																			{linkLoading
																				? "Generating.."
																				: "Generate"}
																		</span>
																	</Button>
																</div>
															</div>
														</Form>
													)}
													<Button
														className="btn-default btn-sm flex-row-center rounded-4"
														onClick={() => setLinkForm(true)}
													>
														<i className="ri-add-line me-1 text-sm" />
														<span className="label-md">New Link</span>
													</Button>
												</>
											)}
										</>
									)}
								</>
							)}
							{assetTab === "activity" &&
								assetLogs?.map((log) => (
									<div
										key={log.id}
										className="asset-activity-container bg-white border-bottom"
									>
										<div
											className="asset-activity-item flex-row-left p-10 pointer"
											onClick={() =>
												setActiveAssetLog(
													activeAssetLog === log.id ? null : log.id
												)
											}
										>
											<div>
												<p className="activity-log-content text-sm mb-1">
													{log.description}
												</p>
												<div className="flex-row-left">
													<span className="label-md opacity-7">
														{format(
															new Date(`${log.created_at}Z`),
															"MMM dd, yyyy - hh:mm a"
														)}
													</span>
												</div>
											</div>
											<i
												className={`activity-dropdown ri-arrow-down-s-line text-xl ${
													activeAssetLog === log.id && "active"
												}`}
											/>
										</div>
										<div
											className={`activity-body p-10 mb-15 ${
												activeAssetLog === log.id && "active"
											}`}
										>
											<div className="rounded border p-10">
												{log.device_flag && (
													<div className="activity-warning-container rounded-4 flex-row-left mb-15 ">
														<i className="ri-information-line text-lg me-1" />
														<p className="text-sm mb-0">{log.device_flag}</p>
													</div>
												)}
												{log.loc_flag && (
													<div className="activity-warning-container rounded-4 flex-row-left mb-15 ">
														<i className="ri-information-line text-lg me-1" />
														<p className="text-sm mb-0">{log.loc_flag}</p>
													</div>
												)}
												<div className="activity-info mb-1">
													<span className="text-medium-inter text-sm">
														Host:{" "}
													</span>
													<span className="text-light-inter text-sm">
														{log.origin_host}
													</span>
												</div>
												<div className="activity-info mb-1">
													<span className="text-medium-inter text-sm">
														Browser:{" "}
													</span>
													<span className="text-light-inter text-sm">
														{log.browser}
													</span>
												</div>
												<div className="activity-info mb-1">
													<span className="text-medium-inter text-sm">
														Operating System:{" "}
													</span>
													<span className="text-light-inter text-sm">
														{log.operating_system}
													</span>
												</div>
												<div className="activity-info mb-1">
													<span className="text-medium-inter text-sm">
														Device:{" "}
													</span>
													<span className="text-light-inter text-sm">
														{log.device_brand} {log.device_model}
													</span>
												</div>
												<div className="activity-info">
													<span className="text-medium-inter text-sm">
														Location:{" "}
													</span>
													<span className="text-light-inter text-sm">
														{log.origin_city && log.origin_city + ", "}
														{log.origin_country}
													</span>
												</div>
											</div>
										</div>
									</div>
								))}
						</>
					)}
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="light"
						className="modal-btn btn-md dismiss-btn text-medium-inter text-sm"
						onClick={closeAssetModal}
					>
						Close
					</Button>
					{canEdit &&
						(assetTab === "details" ? (
							<Button
								className="modal-btn btn-md btn-default text-medium-inter text-sm"
								onClick={handleAssetEdit}
								disabled={actionLoading}
							>
								{actionLoading ? "Saving.." : "Save Changes"}
							</Button>
						) : shareTo?.length !== 0 ? (
							<Button
								type="button"
								className="modal-btn btn-md btn-default text-medium-inter text-sm"
								onClick={shareAsset}
								disabled={actionLoading}
							>
								{actionLoading ? "Sharing.." : "Share"}
							</Button>
						) : (
							""
						))}
				</Modal.Footer>
			</Modal>
			<Modal show={assetRequestModal} onHide={closeAssetRequestModal} centered>
				<Modal.Header>
					<div>
						<span className="text-medium-inter text-lg">Request Asset</span>
						<p className="m-0 text-sm opacity-5">
							Can't find the asset you're looking for? You can request it from
							the safe owner.
						</p>
					</div>
				</Modal.Header>
				<Form onSubmit={requestAsset}>
					<Modal.Body>
						<Row className="form-block-sm">
							<Col>
								<Form.Label>Name</Form.Label>
								<Form.Control
									type="text"
									className="form-input input-lg text-sm rounded-4"
									placeholder={canEdit ? "Enter asset name." : ""}
									value={assetRequestName}
									onChange={(e) => setAssetRequestName(e.target.value)}
								/>
							</Col>
						</Row>
						<Row className="form-block-sm">
							<Col>
								<Form.Label>Type</Form.Label>
								<Form.Select
									className="form-input input-lg text-sm rounded-4"
									value={assetRequestType}
									onChange={(e) => setAssetRequestType(e.target.value)}
								>
									<option value="Login">Login</option>
									<option value="MFA">2FA</option>
									<option value="Card">Card</option>
									<option value="Address">Address</option>
									<option value="Contact">Contact</option>
									<option value="API">API Credentials</option>
								</Form.Select>
							</Col>
						</Row>
						<Row className="form-block-sm">
							<Col>
								<Form.Label>Description</Form.Label>
								<Form.Control
									as="textarea"
									className="form-input asset-notes input-lg text-sm rounded-4"
									placeholder="Tell the safe owner what you need."
									value={assetRequestDesc}
									onChange={(e) => setAssetRequestDesc(e.target.value)}
								/>
							</Col>
						</Row>
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="light"
							className="modal-btn btn-md dismiss-btn text-medium-inter text-sm"
							onClick={closeAssetRequestModal}
						>
							Cancel
						</Button>
						<Button
							type="submit"
							className="modal-btn btn-md btn-default text-medium-inter text-sm"
							disabled={actionLoading}
						>
							{actionLoading ? "Saving.." : "Save"}
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
			<Modal
				show={assetRequestInfo}
				onHide={() => setAssetRequestInfo(null)}
				centered
			>
				<Modal.Header>
					<div>
						<span className="text-medium-inter text-lg">Asset Request</span>
					</div>
				</Modal.Header>
				<Modal.Body>
					<Row className="mb-10">
						<Col className="col-4">
							<span className="text-sm text-medium-inter">Asset Name:</span>
						</Col>
						<Col>
							<span className="text-sm">{assetRequestInfo?.name}</span>
						</Col>
					</Row>
					<Row className="mb-10">
						<Col className="col-4">
							<span className="text-sm text-medium-inter">Type:</span>
						</Col>
						<Col>
							<span className="text-sm">{assetRequestInfo?.type_}</span>
						</Col>
					</Row>
					<Row className="mb-10">
						<Col className="col-4">
							<span className="text-sm text-medium-inter">Description:</span>
						</Col>
						<Col>
							<p className="text-sm mb-0">
								{assetRequestInfo?.description || "--"}
							</p>
						</Col>
					</Row>
					<Row className="mb-10">
						<Col className="col-4">
							<span className="text-sm text-medium-inter">Requested by:</span>
						</Col>
						<Col>
							<span className="text-sm">{assetRequestInfo?.user.username}</span>
						</Col>
					</Row>
					<Row className="mb-10">
						<Col className="col-4">
							<span className="text-sm text-medium-inter">Created:</span>
						</Col>
						<Col>
							{assetRequestInfo && (
								<span className="text-sm">
									{format(
										new Date(assetRequestInfo.created_at),
										"MMM dd, yyyy - hh:mm a"
									)}
								</span>
							)}
						</Col>
					</Row>
				</Modal.Body>
				<Modal.Footer>
					{isOwner && (
						<>
							<Button
								variant="outline-danger"
								className="modal-btn btn-md text-medium-inter text-sm"
								disabled={actionLoading}
								onClick={rejectAssetRequest}
							>
								{rejectAssetLoading ? "Rejecting.." : "Reject"}
							</Button>
							<Button
								className="modal-btn btn-md btn-default text-medium-inter text-sm"
								onClick={acceptAssetRequest}
							>
								Create Asset
							</Button>
						</>
					)}
				</Modal.Footer>
			</Modal>
			<Modal
				show={exportModal}
				onHide={() => {
					setExportModal(false);
					setExportType("json");
				}}
				centered
			>
				<Modal.Header className="text-medium-inter text-lg">
					Export Assets
				</Modal.Header>
				<Form onSubmit={exportAssets}>
					<Modal.Body>
						<Row className="form-block-sm">
							<Col>
								<Form.Label>File Type</Form.Label>
								<Form.Select
									className="form-input input-lg text-sm rounded-4"
									value={exportType}
									onChange={(e) => setExportType(e.target.value)}
								>
									<option value="json">JSON</option>
									<option value="csv">CSV</option>
								</Form.Select>
							</Col>
						</Row>
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="light"
							className="modal-btn btn-sm dismiss-btn text-medium-inter text-sm"
							onClick={() => {
								setExportModal(false);
								setExportType("json");
							}}
						>
							Cancel
						</Button>
						<Button
							type="submit"
							className="modal-btn btn-default btn-sm text-medium-inter text-sm"
							disabled={exportLoading}
						>
							{exportLoading ? "Exporting.." : "Export"}
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
			<Modal
				show={importModal}
				onHide={() => {
					setImportModal(false);
					setImportFile(null);
				}}
				centered
			>
				<Modal.Header className="text-medium-inter text-lg">
					Import Assets
				</Modal.Header>
				<Form onSubmit={readImportFile}>
					<Modal.Body>
						<Row className="form-block-sm">
							<Col>
								<Form.Label>Provider</Form.Label>
								<Form.Select
									className="form-input rounded-4"
									value={provider}
									onChange={(e) => setProvider(e.target.value)}
								>
									<option value="Apple">Apple</option>
									<option value="Bitwarden">Bitwarden</option>
									<option value="LastPass">LastPass</option>
								</Form.Select>
							</Col>
						</Row>
						<Row className="form-block-sm">
							<Col>
								<Form.Label>Import File</Form.Label>
								<Form.Control
									type="file"
									accept=".json,.csv"
									className="form-input rounded-4"
									onChange={handleFileChange}
								/>
							</Col>
						</Row>
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="light"
							className="modal-btn btn-sm dismiss-btn text-medium-inter text-sm"
							onClick={() => {
								setImportModal(false);
								setImportFile(null);
							}}
						>
							Cancel
						</Button>
						<Button
							type="submit"
							className="modal-btn btn-default btn-sm text-medium-inter text-sm"
							disabled={importLoading}
						>
							{importLoading ? "Importing.." : "Import"}
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		</>
	);
};

export default Group;
